import { TableTypes } from '@cango-app/types'
import { GridCellCoordinates } from '@mui/x-data-grid-premium'
import clipboard from 'clipboardy'

import { showSnackbar } from 'src/helpers/snackbarManager'

export type COPY_CLIPBOARD = {
	type: 'data' | 'row_calculation' | 'descendants'
	data: any
}

export const handleSpecialPasteFromClipboard = async (
	records: TableTypes.Record[],
	selectedCells: GridCellCoordinates[],
) => {
	const rawClipboardData = await clipboard.read()
	const clipboardData: COPY_CLIPBOARD = JSON.parse(rawClipboardData)

	if (!clipboardData?.type || !clipboardData.data) {
		showSnackbar('Clipboard cannot be pasted', { variant: 'error' })
		return []
	}

	return records.map(({ _id, ..._record }) => {
		const foundRow = selectedCells.find(({ id }) => _id === id)
		if (!foundRow) {
			return {
				_id,
				..._record,
			}
		}
		return {
			_id,
			data: _record.data,
			...(clipboardData.type === 'data' && {
				data: {
					[foundRow.field]: clipboardData.data,
				},
			}),
			...(clipboardData.type === 'row_calculation' && {
				calculations: {
					[foundRow.field]: clipboardData.data,
				},
			}),
			...(clipboardData.type === 'descendants' && {
				descendants: clipboardData.data,
			}),
		}
	}) as TableTypes.Record[]
}
