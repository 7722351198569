export type RecentlyViewedProject = {
	_id: string
	name: string
	blueprintId?: string
}

export enum ProjectGroupingType {
	Tags = 'tags',
	Sections = 'sections',
}

export interface PersistedConfigState {
	recentlyViewedProjects: { [organisationId: string]: RecentlyViewedProject[] }
	isBulkEditDatabasesEnabled: boolean
	projectGrouping?: {
		[projectId: string]: ProjectGroupingType
	}
	showAnswers?: {
		[tableId: string]: {
			[columnId: string]: boolean
		}
	}
}
