import React, { ComponentType } from 'react'
import dayjs from 'dayjs'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import PersonIcon from '@mui/icons-material/Person'
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined'
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined'
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined'
import { V3BlueprintSdk } from '@cango-app/sdk'

import { Box, Chip, Text, PulsatingDot } from 'src/components'

import { CardContainer } from './card-container'
import { CardMenuType } from './index'

type CardProps = V3BlueprintSdk.Card & {
	shouldHide?: boolean
	onSelectBlueprint: (_id: string) => void
	menuType: CardMenuType
}

type CardInformationDetailProps = {
	icon: JSX.Element
	text: string
}

const CardInformationDetail: ComponentType<CardInformationDetailProps> = ({ icon, text }) => (
	<Box display="flex" alignItems="center" mb={0.5}>
		{icon}
		<Text fontSize={14} ml={1}>
			{text}
		</Text>
	</Box>
)

export const Card: ComponentType<CardProps> = ({
	created_by,
	numberOfPendingTasks,
	updatedAt,
	active,
	_id,
	name,
	shouldHide,
	onSelectBlueprint,
	createdAt,
	menuType,
	isValid,
}) => {
	if (shouldHide) {
		return null
	}

	return (
		<CardContainer onClick={() => onSelectBlueprint(_id)} menuType={menuType} shouldGrey={!active}>
			<Box>
				{active && isValid !== undefined && menuType === 'blueprint' && (
					<Chip
						icon={
							<PulsatingDot
								variant={!isValid ? 'error' : 'success'}
								size="small"
								containerStyle={{ pl: 1 }}
							/>
						}
						label={
							<span>
								<b>Status:</b> {!isValid ? 'Invalid' : 'Active'}
							</span>
						}
						variant="status"
						color="active"
						sx={{
							float: 'right',
							ml: 1,
							opacity: 0.9,
							color: 'inherit',
						}}
						size="small"
					/>
				)}
				<Text fontSize={18} fontWeight="bold" color="white" sx={{ mb: 2 }}>
					{name}
				</Text>
			</Box>
			<CardInformationDetail
				icon={<PlayCircleIcon />}
				text={`Created ${dayjs.unix(createdAt).format('D MMM YYYY')}`}
			/>
			<CardInformationDetail
				icon={<PendingActionsOutlinedIcon />}
				text={`Number of pending tasks: ${numberOfPendingTasks}`}
			/>
			<CardInformationDetail
				icon={<SyncOutlinedIcon />}
				text={`Last update: ${dayjs.unix(updatedAt).format('D MMM YYYY')}`}
			/>
			{active && (
				<CardInformationDetail
					icon={<ToggleOnOutlinedIcon />}
					text={`Active since: ${dayjs.unix(createdAt).fromNow()}`}
				/>
			)}
			{created_by.length > 2 && (
				<CardInformationDetail icon={<PersonIcon />} text={`Created by: ${created_by}`} />
			)}
		</CardContainer>
	)
}
