import React, { ComponentType, useState } from 'react'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import FolderIcon from '@mui/icons-material/Folder'
import { FilesSdk } from '@cango-app/sdk'
import Checkbox from '@mui/material/Checkbox'
import { ListItemButton, ListItemIcon } from '@mui/material'

import { useIsMobile } from '../../../hooks/useMobile'
import { Box } from '../../box'
import { Text } from '../../text'
import { Button } from '../../button'
import { AttachedFileComponent } from '../../attached-file/attached-file-component'

type FilesPanelProps = {
	parentFolderName?: string
	selectedFiles: string[]
	setSelectedFiles: (ids: string[]) => void
	files: FilesSdk.GetFilesByFolderIdResponse
	onSubmit: (fileIds: string[]) => Promise<void> | void
	fetchError?: boolean
	isLoadingFiles?: boolean
	attemptNumber?: number
	countdown?: number
}

export const FilesPanel: ComponentType<FilesPanelProps> = ({
	selectedFiles,
	setSelectedFiles,
	parentFolderName,
	files,
	onSubmit,
	fetchError,
	isLoadingFiles,
	attemptNumber,
	countdown,
}) => {
	const isMobile = useIsMobile()
	const [isSavingSelection, setIsSavingSelection] = useState(false)

	const handleSelectFile = (fileId: string, isSelected: boolean) => {
		if (!selectedFiles.includes(fileId) && isSelected) {
			setSelectedFiles([...selectedFiles, fileId])
			return
		}

		if (selectedFiles.includes(fileId) && !isSelected) {
			const filtered = selectedFiles.filter((_fileId) => _fileId !== fileId)
			setSelectedFiles([...filtered])
		}
	}

	const handleSubmitSelection = async () => {
		const filesInDriveFolder = files.map((file) => file.id)
		const newSelection = selectedFiles.filter((fileId) => filesInDriveFolder.includes(fileId))
		setIsSavingSelection(true)
		await onSubmit(newSelection)
		setIsSavingSelection(false)
	}

	return (
		<List
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
			}}
		>
			{fetchError && (
				<Box mt={1}>
					<Alert severity="info">
						<AlertTitle>Your files have uploaded</AlertTitle>
						Google is taking a little longer than usual to finalize the uploads. Please return in a
						few minutes to see them here. <b>Uploading them again will only duplicate them</b>.
					</Alert>
				</Box>
			)}
			<Box
				display="flex"
				alignItems="center"
				height="73px"
				boxShadow={1}
				flexDirection={{ laptop: 'row', mobile: 'column' }}
				px={{ mobile: 2, laptop: 0 }}
			>
				{!isMobile && (
					<ListItem sx={{ flex: 1 }}>
						<ListItemAvatar>
							<Avatar>
								<FolderIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primaryTypographyProps={{ fontWeight: 'bold' }}
							primary={
								attemptNumber && attemptNumber > 0
									? `Retrying in ${countdown} second${countdown !== 1 ? 's' : ''}`
									: parentFolderName
							}
							secondary={
								attemptNumber && attemptNumber > 0
									? 'Waiting for Google to finalize uploads...'
									: ''
							}
						/>
					</ListItem>
				)}
				<Button
					sx={{ mr: { laptop: 1 }, width: { mobile: '100%', laptop: 'inherit' } }}
					disabled={isSavingSelection || isLoadingFiles}
					isLoading={isSavingSelection || isLoadingFiles}
					onClick={handleSubmitSelection}
				>
					Confirm selection
				</Button>
			</Box>
			<Box overflow="auto" boxSizing="border-box">
				{files.length ? (
					files.map((file) => {
						const labelId = `checkbox-list-label-${file.id}`
						const isFileSelected = selectedFiles.includes(file.id)
						return (
							<ListItem key={file.id}>
								<ListItemButton
									role={undefined}
									onClick={() => handleSelectFile(file.id, !isFileSelected)}
								>
									<ListItemIcon>
										<Checkbox
											checked={selectedFiles.includes(file.id)}
											disableRipple
											tabIndex={-1}
											edge="start"
											inputProps={{ 'aria-labelledby': labelId }}
										/>
									</ListItemIcon>
									<ListItemText>
										<AttachedFileComponent file={file} isUploadingAsync={false} />
									</ListItemText>
								</ListItemButton>
							</ListItem>
						)
					})
				) : (
					<ListItem>
						<Text>You have no files uploaded</Text>
					</ListItem>
				)}
			</Box>
		</List>
	)
}
