import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { ComponentType, PropsWithChildren, useRef } from 'react'

import './transition.css'

type Props = PropsWithChildren & {
	activeQuestionId?: string
	isGoingForward: boolean
}

export const QuestionEffect: ComponentType<Props> = ({
	children,
	activeQuestionId,
	isGoingForward,
}) => {
	const nodeRef = useRef(null)

	return (
		<SwitchTransition mode="out-in">
			<CSSTransition
				key={activeQuestionId}
				nodeRef={nodeRef}
				timeout={500}
				classNames={isGoingForward ? 'fade' : 'fade-backwards'}
			>
				<div ref={nodeRef}>{children}</div>
			</CSSTransition>
		</SwitchTransition>
	)
}
