import {
	useState,
	useCallback,
	ComponentType,
	createContext,
	useEffect,
	PropsWithChildren,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { V3ProjectSdk } from '@cango-app/sdk'
import _set from 'lodash/set'
import { V3ClientTypes } from '@cango-app/types'

import { selectors as authSelectors } from 'src/store/modules/auth'
import { errorHandler } from 'src/helpers/api'
import { showSnackbar } from 'src/helpers/snackbarManager'
import usePolling from 'src/hooks/usePolling'
import { actions as projectActions } from 'src/store/modules/projects-v3'

export type TaskProviderChildProps = {
	task: V3ClientTypes.Project.Task | undefined
	isLoadingTask: boolean
	updateTask: (
		key: keyof V3ProjectSdk.UpdateTaskRequest['update'],
		value: any,
		options?: { updateDb?: boolean; localStateKey?: string; referencedTaskId?: string },
	) => Promise<void>
	setNextTaskDbChains: React.Dispatch<React.SetStateAction<V3ProjectSdk.TaskDbChains>>
	nextTaskDbChains: V3ProjectSdk.TaskDbChains
	onClose?: () => void
}

export const TaskContext = createContext<TaskProviderChildProps>({
	task: undefined,
	isLoadingTask: false,
	updateTask: async () => undefined,
	setNextTaskDbChains: () => [],
	nextTaskDbChains: {} as V3ProjectSdk.TaskDbChains,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onClose: () => {},
})

export const TaskProvider: ComponentType<
	PropsWithChildren<{
		taskId?: string
		fetchFiles?: () => void
		onTaskLoading?: (isLoading: boolean) => void
		onClose?: () => void
	}>
> = (props) => {
	const dispatch = useDispatch()
	const [isLoadingTask, setIsLoadingTask] = useState(false)
	const [task, setTask] = useState<V3ClientTypes.Project.Task | undefined>()
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [nextTaskDbChains, setNextTaskDbChains] = useState<V3ProjectSdk.TaskDbChains>({})

	const isPendingFileUploads = !!task?.actions.some((_action) =>
		_action.file_ids.includes(V3ProjectSdk.UPLOADING_TEXT),
	)

	const handleLoadingToggle = (loading: boolean) => {
		setIsLoadingTask(loading)
		if (props.onTaskLoading) {
			props.onTaskLoading(loading)
		}
	}

	const updateTask = useCallback(
		async (
			key: keyof V3ProjectSdk.UpdateTaskRequest['update'],
			value: any,
			options?: { updateDb?: boolean; localStateKey?: string; referencedTaskId?: string },
		) => {
			const taskIdToUpdate = options?.referencedTaskId ?? task?._id
			if (!taskIdToUpdate) return

			try {
				if (options?.updateDb) {
					await V3ProjectSdk.updateTask({
						baseURL: import.meta.env.VITE_API as string,
						authHeaders,
						data: {
							taskId: taskIdToUpdate,
							update: {
								[key]: value,
							},
						},
					})
				}

				setTask((_task) => {
					if (!_task) return
					const taskCopy = { ..._task }
					if (options?.referencedTaskId) {
						let taskReferenceIndex: number | undefined = undefined
						const actionIndex = taskCopy.actions.findIndex((action) =>
							action.task_references.find((_ref, index) => {
								if (_ref.task._id === options.referencedTaskId) {
									taskReferenceIndex = index
									return true
								}
							}),
						)

						_set(
							taskCopy,
							`actions.${actionIndex}.task_references.${taskReferenceIndex}.task.${key}`,
							value,
						)

						return { ...taskCopy }
					}
					_set(taskCopy, options?.localStateKey ?? key, value)
					return taskCopy
				})
				dispatch(projectActions.updateStoreTask({ taskId: taskIdToUpdate, key: key as any, value }))
			} catch (error) {
				showSnackbar('Could not update task', { variant: 'error' })
			}
		},
		[setTask, task],
	)

	const getTasksPendingUploadedFiles = useCallback(async () => {
		if (!task || !isPendingFileUploads || !props.fetchFiles) return
		try {
			const response = await V3ProjectSdk.getTasksPendingUploadedFiles({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders,
				data: {
					taskIds: [task._id],
				},
			})
			if (response.length) {
				props.fetchFiles()
				response.forEach((_task) => {
					if (task._id !== _task._id) return
					updateTask('actions', _task.actions)
				})
			}
		} catch (error) {
			showSnackbar('Could not fetch files', { variant: 'error' })
		}
	}, [isPendingFileUploads, task?._id])
	const [isPolling, startPolling, stopPolling] = usePolling(getTasksPendingUploadedFiles, 5000)

	const fetchTask = useCallback(async () => {
		if (!props.taskId) return
		try {
			handleLoadingToggle(true)
			const response = await V3ProjectSdk.getTask(import.meta.env.VITE_API as string, authHeaders, {
				taskId: props.taskId,
			})
			setTask(response)
		} catch (error) {
			errorHandler({ error, dispatch, message: 'Could not fetch files' })
		} finally {
			handleLoadingToggle(false)
		}
	}, [props.taskId, authHeaders])

	useEffect(() => {
		if (!isPolling && task && isPendingFileUploads) {
			startPolling()
		}

		if (isPolling && (!isPendingFileUploads || !task)) {
			stopPolling()
		}

		return () => {
			if (isPolling) {
				stopPolling()
			}
		}
	}, [isPendingFileUploads, isPolling, task])

	useEffect(() => {
		if (props.taskId) {
			fetchTask()
		}
	}, [props.taskId])

	return (
		<TaskContext.Provider
			value={{
				task: task,
				isLoadingTask,
				updateTask,
				setNextTaskDbChains,
				nextTaskDbChains,
				onClose: props.onClose,
			}}
		>
			{props.children}
		</TaskContext.Provider>
	)
}
