import React, { ComponentType, useCallback, useEffect, useState } from 'react'
import { FilesSdk, V3ProjectSdk } from '@cango-app/sdk'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonProps, SxProps } from '@mui/material'

import { selectors as authSelectors } from 'src/store/modules/auth'
import { actions as persistedFilesActions } from 'src/store/modules/persisted-files'
import { errorHandler } from 'src/helpers/api'

import { AttachedFileComponent } from './attached-file-component'

type AttachedFileProps = {
	fileId: string
	onFetchFail?: () => void
	containerStyles?: SxProps
	withThumbnail?: boolean
	withOpen?: boolean
	size?: ButtonProps['size']
	_file: FilesSdk.File | undefined
	parentFolderId: string | undefined
	parentFolderName: string | undefined
	onRemoveFileFromTask?: (fileId: string) => void
}

export const AttachedFile: ComponentType<AttachedFileProps> = ({
	fileId,
	onFetchFail,
	_file,
	parentFolderId,
	parentFolderName,
	onRemoveFileFromTask,
}) => {
	const dispatch = useDispatch()
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [fileState, setFileState] = useState<{
		file?: FilesSdk.File
		loading: boolean
		failed: boolean
	}>({
		file: _file,
		loading: false,
		failed: false,
	})
	const selectedFile = fileState.file

	const isUploadingAsync = fileId === V3ProjectSdk.UPLOADING_TEXT

	const fetchFile = useCallback(async () => {
		if (fileId) {
			try {
				setFileState({
					...selectedFile,
					loading: true,
					failed: false,
				})
				const response = await FilesSdk.getFileById(
					import.meta.env.VITE_API as string,
					authHeaders,
					{
						fileId,
					},
				)
				setFileState((prevValue) => ({
					...prevValue,
					file: response,
				}))
				if (parentFolderId) {
					dispatch(
						persistedFilesActions.setAddNewFilesToProjectFiles({
							id: parentFolderId,
							files: [response],
							parentFolderName,
						}),
					)
				}
			} catch (error) {
				errorHandler({
					error,
					dispatch,
					message: 'Error fetching file',
				})

				setFileState((prevValue) => ({
					...prevValue,
					failed: true,
				}))
				if (onFetchFail) {
					onFetchFail()
				}
			} finally {
				setFileState((prevValue) => ({
					...prevValue,
					loading: false,
				}))
			}
		}
	}, [fileId])

	const handleUpdatedFile = (file: FilesSdk.File) => {
		if (!parentFolderId) {
			return
		}
		setFileState((prevValue) => ({
			...prevValue,
			file,
		}))
		dispatch(
			persistedFilesActions.setAddNewFilesToProjectFiles({
				id: parentFolderId,
				files: [file],
				parentFolderName,
			}),
		)
	}

	useEffect(() => {
		if (fileId && !isUploadingAsync && fileId !== selectedFile?.id && !_file) {
			fetchFile()
		}
	}, [fileId])

	return (
		<AttachedFileComponent
			file={selectedFile}
			isLoading={fileState.loading}
			onClick={() => {
				if (!selectedFile?.webViewLink) return
				window.open(selectedFile.webViewLink, '_blank')
			}}
			containerProps={{ mr: 1, mb: 1 }}
			isUploadingAsync={isUploadingAsync}
			onUpdateFile={handleUpdatedFile}
			onRemoveFile={onRemoveFileFromTask ? () => onRemoveFileFromTask(fileId) : undefined}
		/>
	)
}
