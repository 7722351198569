import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import * as userThunks from '../user/thunks'

import { ContactState, Contact } from './types'
import * as thunks from './thunks'

const initialState: ContactState = {
	contacts: [],
}

export const contactSlice = createSlice({
	name: 'contacts',
	initialState,
	reducers: {
		setContacts(state: ContactState, action: PayloadAction<Contact[]>) {
			state.contacts = action.payload
		},
		endSession: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(thunks.getContacts.fulfilled, (state: ContactState, action) => {
			state.contacts = action.payload
		})
		builder.addCase(thunks.createContact.fulfilled, (state: ContactState, action) => {
			state.contacts.push(action.payload)
		})
		builder.addCase(thunks.updateContact.fulfilled, (state: ContactState, action) => {
			const { _id, update } = action.meta.arg
			state.contacts = state.contacts.map((contact) => {
				if (contact._id === _id) {
					return { ...contact, ...update }
				}
				return contact
			})
		})
		builder.addCase(thunks.deleteContact.fulfilled, (state: ContactState, action) => {
			state.contacts = [...state.contacts.filter((contact) => contact._id !== action.meta.arg._id)]
		})
		// Clear contact cache on add or remove user
		builder.addCase(userThunks.createUser.fulfilled, (state: ContactState) => {
			state.contacts = []
		})
		builder.addCase(userThunks.deleteUser.fulfilled, (state: ContactState) => {
			state.contacts = []
		})
	},
})
