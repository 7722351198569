import { TableTypes } from '@cango-app/types'
import { GridFooterContainer, GridSlotsComponentsProps } from '@mui/x-data-grid-premium'
import React, { ComponentType, useContext } from 'react'

import { PlusIcon } from 'src/assets/icons'
import { Button } from 'src/components'
import { TableContext } from 'src/providers/table-provider'

export const CustomFooter: ComponentType<NonNullable<GridSlotsComponentsProps['footer']>> = ({
	isStatic,
}) => {
	const { onAddRow, apiRef, table } = useContext(TableContext)

	const handleAddRow = () => {
		if (!table) {
			return
		}
		if (table.type === TableTypes.TableType.Questionaire) {
			const selectedModel = apiRef.current.getCellSelectionModel()
			const selectedCells = Object.keys(selectedModel).reduce((acc: string[], key) => {
				if (selectedModel[key]) {
					acc.push(key)
				}
				return acc
			}, [])
			const rows = apiRef.current.getAllRowIds()
			const lastSelectedCell = selectedCells.reduce(
				(acc: { id: string; index: number }, rowId) => {
					const indexOfRow = rows.findIndex((_record) => _record === rowId)
					if (indexOfRow > acc.index) {
						acc.id = rowId
						acc.index = indexOfRow
					}
					return acc
				},
				{
					id: '',
					index: -1,
				},
			)

			const lastSelectedCellId = lastSelectedCell.id
			if (lastSelectedCellId) {
				onAddRow({ parentId: lastSelectedCellId })
				return
			}
		}

		onAddRow()
	}

	if (isStatic) {
		return null
	}

	return (
		<GridFooterContainer>
			<Button
				variant="text"
				size="small"
				sx={{ minWidth: 100, px: 2 }}
				startIcon={<PlusIcon width={16} />}
				onClick={handleAddRow}
			>
				New row
			</Button>
		</GridFooterContainer>
	)
}
