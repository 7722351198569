import { TableTypes } from '@cango-app/types'
import _orderBy from 'lodash/orderBy'
import { GridFilterItem } from '@mui/x-data-grid-pro'
import {
	getGridNumericOperators,
	getGridSingleSelectOperators,
	getGridStringOperators,
	getGridBooleanOperators,
	GridColType,
	GridFilterModel,
	GridRowModel,
} from '@mui/x-data-grid-premium'
import dayjs from 'dayjs'
import _isArray from 'lodash/isArray'
import _toLower from 'lodash/toLower'
import _has from 'lodash/has'
import { TablesSdk } from '@cango-app/sdk'

import { SingleSelectForm } from './column-settings/types'

const buildDependencyGraph = (fields: TableTypes.Field[]) => {
	const graph = new Map<string, Set<string>>()
	fields.forEach((field) => {
		if (field.type === 'calculation') {
			field.calculation.forEach((part) => {
				if (part.type === TableTypes.FormulaSliceType.FIELD) {
					if (!graph.has(field._id)) {
						graph.set(field._id, new Set())
					}
					graph.get(field._id)?.add(part.value)
				}
			})
		}
	})
	return graph
}

const topologicalSort = (fields: TableTypes.Field[], dependencyGraph: Map<string, Set<string>>) => {
	const visitedFields = new Map()
	const sortedFields: TableTypes.Field[] = []

	const visitField = (fieldId: string) => {
		if (visitedFields.get(fieldId)) {
			return
		}
		visitedFields.set(fieldId, true)

		const dependencies = dependencyGraph.get(fieldId)
		if (dependencies) {
			dependencies.forEach((dependentFieldId) => {
				visitField(dependentFieldId)
			})
		}

		const field = fields.find((field) => field._id === fieldId)
		if (field) {
			sortedFields.push(field)
		}
	}

	dependencyGraph.forEach((_, fieldId: string) => {
		visitField(fieldId)
	})

	fields.forEach((field) => {
		if (!visitedFields.get(field._id)) {
			sortedFields.push(field)
		}
	})

	return sortedFields
}

export type ResolvedRowData = { _id: string } & TableTypes.Record['data']

type ResolveAnyRowCalculations = {
	fields: TableTypes.Field[]
	row: Omit<TableTypes.Record, 'calculations'>
	referenceColumns: TableTypes.ReferenceColumnNames
	questionaireAnswers: TableTypes.QuestionaireAnswer[]
}

const getRowAnswer = ({
	row,
	field,
	referenceColumns,
	questionaireAnswers,
}: {
	row: Omit<TableTypes.Record, 'calculations'>
	field: TableTypes.Field
	referenceColumns: TableTypes.ReferenceColumnNames
	questionaireAnswers: TableTypes.QuestionaireAnswer[]
}): string | undefined => {
	const questionAnswer = TablesSdk.getQuestionResponse({
		questionaireAnswers,
		questionColumnId: field._id,
		rowData: row.data,
	})

	if (!questionAnswer?.length) {
		return
	}

	if (questionAnswer[0]?.text) {
		return questionAnswer[0].text
	}

	const questionOptions = TablesSdk.getQuestionOptions({
		referenceColumns,
		fieldId: field._id,
		row,
	})

	if (!questionOptions.length) {
		return
	}

	const answers = questionAnswer.reduce((_answers: string[], _answer) => {
		if (!_answer.option_id) {
			return _answers
		}
		const answerLabel = questionOptions.find(({ _id }) => _id === _answer.option_id)?.label
		if (answerLabel) {
			_answers.push(answerLabel)
		}
		return _answers
	}, [])

	return answers.join(', ')
}

export const resolveAnyRowCalculations = ({
	row,
	fields,
	referenceColumns,
	questionaireAnswers,
}: ResolveAnyRowCalculations): ResolvedRowData => {
	const dependencyGraph = buildDependencyGraph(fields)
	const sortedFields = topologicalSort(fields, dependencyGraph)
	const rowData: TableTypes.Record['data'] = {}
	sortedFields.forEach((field) => {
		const rowKey = field._id

		if (field.type === TableTypes.FieldType.CALCULATION) {
			let value = row.data[rowKey]
			if (_has(row.overrides, field._id)) {
				value = row.overrides[field._id]
			}
			if (field.returnType === TableTypes.ReturnValueType.boolean) {
				rowData[rowKey] = value === 'TRUE' || value === true || value === 'true'
				return
			}
			rowData[rowKey] = value
			return
		}

		rowData[rowKey] = row.data[rowKey]
		if (field.type === TableTypes.FieldType.QUESTIONAIRE_REFERENCE) {
			rowData[`${field._id}_answer`] = getRowAnswer({
				row,
				field,
				referenceColumns,
				questionaireAnswers,
			})
		}
	})
	return { _id: row._id, ...rowData }
}

export const resolveResources = (resources: TableTypes.ResourceRecord) => {
	if (!resources?.data) return 0
	return resources.data.reduce((acc, record) => {
		const usage = record['usage'] || 1
		return acc + record['rate'] * record['quantity'] * usage
	}, 0)
}

export const getFilters = ({
	table,
	filters,
	mappedColumns,
}: {
	table: TableTypes.CangoTable | undefined
	filters: GridFilterItem[]
	mappedColumns: Map<string, TableTypes.Field>
}) => {
	if (!table || !filters.length) return []
	return filters.reduce((_accFilters: GridFilterItem[], _filter) => {
		const column = mappedColumns.get(_filter.field)
		const columnType = column?.type
		if (!columnType) return _accFilters
		if (columnType === 'reference') {
			return _accFilters
		}
		const stringOperators = getGridStringOperators().map((_operator) => _operator.value)
		const singleSelectOperators = getGridSingleSelectOperators().map((_operator) => _operator.value)
		const numberOperators = getGridNumericOperators().map((_operator) => _operator.value)
		const booleanOperators = getGridBooleanOperators().map((_operator) => _operator.value)

		if (columnType === 'string' && !stringOperators.includes(_filter.operator)) {
			return [
				..._accFilters,
				{
					..._filter,
					operator: 'equals',
				},
			]
		}

		if (
			columnType === 'calculation' &&
			column?.returnType === TableTypes.ReturnValueType.number &&
			!numberOperators.includes(_filter.operator)
		) {
			return [
				..._accFilters,
				{
					..._filter,
					operator: '=',
				},
			]
		}

		if (
			columnType === 'calculation' &&
			column?.returnType === TableTypes.ReturnValueType.string &&
			!stringOperators.includes(_filter.operator)
		) {
			return [
				..._accFilters,
				{
					..._filter,
					operator: 'equals',
				},
			]
		}

		if (
			columnType === 'calculation' &&
			column?.returnType === TableTypes.ReturnValueType.boolean &&
			!booleanOperators.includes(_filter.operator)
		) {
			return [
				..._accFilters,
				{
					..._filter,
					operator: 'is',
				},
			]
		}

		if (columnType === 'singleSelect' && !singleSelectOperators.includes(_filter.operator)) {
			return [
				..._accFilters,
				{
					..._filter,
					operator: 'is',
				},
			]
		}
		return [..._accFilters, _filter]
	}, [])
}

export const getCleanedUpSingleSelectData = (
	newData: SingleSelectForm,
	oldData: {
		options: TableTypes.Field['valueOptions']
		valueOptionFilters: TableTypes.Field['valueOptionFilters']
		single_select_lookup:
			| {
					tableId: string
					fieldId: string
			  }
			| undefined
	},
): {
	valueOptions: TableTypes.Field['valueOptions']
	single_select_lookup: TableTypes.Field['single_select_lookup']
	valueOptionFilters: TableTypes.Field['valueOptionFilters']
} => {
	// cases
	// old lookup new lookup
	// old lookup new custom -> reset single select filters
	// old custom new lookup
	// old custom new custom -> reset single select filters
	const orderedOptions = _orderBy(newData.options, 'label').filter(({ label }) => label)
	if (oldData.single_select_lookup?.tableId && newData.single_select_lookup?.tableId) {
		return {
			...newData,
			valueOptions: [],
		}
	}
	if (oldData.single_select_lookup?.tableId && !newData.single_select_lookup?.tableId) {
		return {
			valueOptions: orderedOptions,
			single_select_lookup: { tableId: '', fieldId: '' },
			valueOptionFilters: newData.valueOptionFilters.map(({ _id, label }) => ({
				_id,
				label,
				filters: { items: [] },
				options: [],
			})),
		}
	}
	if (!oldData.single_select_lookup?.tableId && newData.single_select_lookup?.tableId) {
		return {
			valueOptionFilters: newData.valueOptionFilters.map(({ _id, label }) => ({
				_id,
				label,
				filters: { items: [] },
				options: [],
			})),
			single_select_lookup: newData.single_select_lookup,
			valueOptions: [],
		}
	}
	if (!oldData.single_select_lookup?.tableId && !newData.single_select_lookup?.tableId) {
		return {
			...newData,
			valueOptions: orderedOptions,
			single_select_lookup: { tableId: '', fieldId: '' },
		}
	}
	return {
		...newData,
		valueOptions: newData.options,
	}
}

export const columnValueGetter = (
	value: any,
	field: TableTypes.Field,
	valueOptions: Map<string, { _id: string; label: string }>,
) => {
	if (field.type === TableTypes.FieldType.BOOLEAN) {
		if (value === 'FALSE') {
			return false
		}
		return !!value
	}

	if (
		field.type === TableTypes.FieldType.CALCULATION &&
		field.returnType === TableTypes.ReturnValueType.boolean
	) {
		return value === 'TRUE' || value === true || value === 'true'
	}

	if (field.type === TableTypes.FieldType.RESOURCES) {
		return resolveResources(value)
	}

	if (
		[
			TableTypes.FieldType.TABLE_SELECT,
			TableTypes.FieldType.SINGLE_SELECT,
			TableTypes.FieldType.REFERENCE,
			TableTypes.FieldType.CONTACT,
			TableTypes.FieldType.ROLE,
		].includes(field.type)
	) {
		const _selectedOption = valueOptions.get(value)
		if (!_selectedOption) {
			return value
		}
		return _selectedOption.label
	}

	return value
}

type OperatorFunction = (columnType: GridColType, cellValue: any, filterValue: any) => boolean

const operatorFunctions: Record<string, OperatorFunction> = {
	contains: (columnType, cellValue, filterValue) =>
		String(cellValue).toLowerCase().includes(String(filterValue).toLowerCase()),
	doesNotContain: (columnType, cellValue, filterValue) =>
		!String(cellValue).toLowerCase().includes(String(filterValue).toLowerCase()),
	equals: (columnType, cellValue, filterValue) => {
		if (columnType === 'number') {
			return Number(cellValue) === Number(filterValue)
		}
		return String(cellValue) === String(filterValue)
	},
	doesNotEqual: (columnType, cellValue, filterValue) => {
		if (columnType === 'number') {
			return Number(cellValue) !== Number(filterValue)
		}
		return String(cellValue) !== String(filterValue)
	},
	startsWith: (columnType, cellValue, filterValue) =>
		String(cellValue).toLowerCase().startsWith(String(filterValue).toLowerCase()),
	endsWith: (columnType, cellValue, filterValue) =>
		String(cellValue).toLowerCase().endsWith(String(filterValue).toLowerCase()),
	isEmpty: (columnType, cellValue) => !cellValue || String(cellValue).trim() === '',
	isNotEmpty: (columnType, cellValue) => Boolean(cellValue) && String(cellValue).trim() !== '',
	isAnyOf: (columnType, cellValue, filterValue) => filterValue.includes(cellValue),

	// Number Operators
	'=': (columnType, cellValue, filterValue) => Number(cellValue) === Number(filterValue),
	'!=': (columnType, cellValue, filterValue) => Number(cellValue) !== Number(filterValue),
	'>': (columnType, cellValue, filterValue) => Number(cellValue) > Number(filterValue),
	'>=': (columnType, cellValue, filterValue) => Number(cellValue) >= Number(filterValue),
	'<': (columnType, cellValue, filterValue) => Number(cellValue) < Number(filterValue),
	'<=': (columnType, cellValue, filterValue) => Number(cellValue) <= Number(filterValue),

	// Mixed
	is: (columnType, cellValue, filterValue) => {
		if (columnType === 'date') {
			return dayjs(cellValue).isSame(dayjs(filterValue), 'day')
		}
		if (columnType === 'number') {
			return Number(cellValue) === Number(filterValue)
		}

		if (columnType === 'boolean' && typeof cellValue === 'string') {
			return _toLower(cellValue) === _toLower(String(filterValue))
		}

		return String(cellValue) === String(filterValue)
	},
	not: (columnType, cellValue, filterValue) => {
		if (columnType === 'date') {
			return !dayjs(cellValue).isSame(dayjs(filterValue), 'day')
		}
		if (columnType === 'number') {
			return Number(cellValue) !== Number(filterValue)
		}
		return cellValue !== filterValue
	},

	// Date Operators (using dayjs)
	after: (columnType, cellValue, filterValue) =>
		dayjs(cellValue).isAfter(dayjs(filterValue), 'day'),
	onOrAfter: (columnType, cellValue, filterValue) =>
		dayjs(cellValue).isSame(dayjs(filterValue), 'day') ||
		dayjs(cellValue).isAfter(dayjs(filterValue), 'day'),
	before: (columnType, cellValue, filterValue) =>
		dayjs(cellValue).isBefore(dayjs(filterValue), 'day'),
	onOrBefore: (columnType, cellValue, filterValue) =>
		dayjs(cellValue).isSame(dayjs(filterValue), 'day') ||
		dayjs(cellValue).isBefore(dayjs(filterValue), 'day'),
}

export const applyFilterItem = (
	columnType: GridColType,
	operator: string,
	value: any,
	filterValue: any,
) => {
	const filterFn = operatorFunctions[operator]
	if (!filterFn) {
		return true
	}

	return filterFn(columnType, value, filterValue)
}

function applyTableFilterItem(
	columnType: GridColType,
	row: GridRowModel,
	filterItem: GridFilterItem,
): boolean {
	const { field, operator, value } = filterItem
	const cellValue = row[field]

	return applyFilterItem(columnType, operator, cellValue, value)
}

export const applyFilterModelToRow = ({
	columns,
	row,
	filterModel,
}: {
	columns: {
		_id: string
		type?: GridColType
	}[]
	row: GridRowModel
	filterModel: GridFilterModel
}): boolean => {
	const { items, logicOperator } = filterModel
	const filterOperation = (filterItem: GridFilterItem) => {
		const columnType = columns.find((column) => column._id === filterItem.field)?.type
		if (!columnType) return true
		return applyTableFilterItem(columnType, row, filterItem)
	}
	if (logicOperator === 'or') {
		return items.some(filterOperation)
	}
	return items.every(filterOperation)
}

export const convertRowValueToArray = (rowValue: any): TableTypes.ListOption[] => {
	if (_isArray(rowValue)) {
		return rowValue
	}
	return []
}

type LinkedTable = TablesSdk.MenuTable & {
	prefix: string
}

export const getLinkedTable = (
	table: TablesSdk.MenuTable,
	tableList: TablesSdk.MenuTable[],
): LinkedTable | undefined => {
	let linkedTable: TablesSdk.MenuTable | undefined
	if (table.type === TableTypes.TableType.Questionaire) {
		linkedTable = tableList.find((_table) => _table.questionaire_reference_table === table._id)
	} else {
		const reference = table.questionaire_reference_table
		linkedTable = tableList.find((_table) => _table._id === reference)
	}
	if (!linkedTable) {
		return
	}
	return {
		...linkedTable,
		prefix: linkedTable.type === TableTypes.TableType.Questionaire ? 'Questionaire' : 'Linked to',
	}
}
