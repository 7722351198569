import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { ComponentType, useContext, useEffect, useMemo, useState } from 'react'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import _isNumber from 'lodash/isNumber'
import {
	GridRowId,
	GridRowTreeConfig,
	GridValidRowModel,
	isAutogeneratedRow,
	useGridApiContext,
} from '@mui/x-data-grid-premium'
import _isEmpty from 'lodash/isEmpty'
import { TableTypes } from '@cango-app/types'

import { Button } from 'src/components'
import { TableContext } from 'src/providers/table-provider'
import { showSnackbar } from 'src/helpers/snackbarManager'

import { getGroupedValue } from '../../components/display-view/get-group-id'
import useConfirmGroupDuplication from '../../hooks/use-confirm-group-duplication'

import { handleSpecialPasteFromClipboard } from './right-click-menu/utils'

type ActionsButtonProps = {
	permittedFunctions?: TableTypes.ActionFunction[]
	groupedFields?: TableTypes.GroupedField[]
}

const getChildRows = ({ tree, rowId }: { tree: GridRowTreeConfig; rowId: string }): string[] => {
	const childRowIds: string[] = []
	const row = tree[rowId]

	if (row.type === 'leaf') {
		childRowIds.push(rowId)
	}

	if (row.type === 'group') {
		const rowChildren = row.children.map((childId) =>
			getChildRows({ tree, rowId: childId as string }),
		)
		childRowIds.push(...rowChildren.flat())
	}

	return childRowIds
}

export const ActionsButton: ComponentType<ActionsButtonProps> = ({
	permittedFunctions,
	groupedFields = [],
}) => {
	const { duplicateRows, onAddRow, table, onDeleteRecords, mappedRowData, updateMultipleRecords } =
		useContext(TableContext)
	const apiRef = useGridApiContext()
	const [NewNameDialog, confirmGroupName] = useConfirmGroupDuplication()
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
	const open = Boolean(anchorEl)
	const selectedCells = apiRef.current.getCellSelectionModel()
	const selectedRows = apiRef.current.getSelectedRows()
	const hasCellsSelected = !_isEmpty(selectedCells)
	const hasRowsSelected = selectedRows.size > 0

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleAddRow = async (position: number) => {
		await onAddRow({ position })
		apiRef.current.setRowSelectionModel([])
	}

	const handleDuplicateRows = async () => {
		if (!selectedRows.size) {
			return
		}

		const groupValues = [...selectedRows.keys()].reduce((_rowIds: string[], _rowId) => {
			const row = apiRef.current.getRow(_rowId)
			if (isAutogeneratedRow(row)) {
				return [..._rowIds, _rowId as string]
			}
			return _rowIds
		}, [])

		if (groupValues.length && !groupedFields.length) {
			return
		}

		if (groupValues.length) {
			for (const groupKey of groupValues) {
				const currentDepth = apiRef.current.state.rows.tree[groupKey].depth
				const viewGroup = groupedFields[currentDepth]
				if (!viewGroup) {
					return
				}
				const currentGroupName = getGroupedValue(groupKey)

				const newGroupName = await confirmGroupName(currentGroupName, viewGroup.groupColumnId)
				if (!newGroupName.name || newGroupName.state === 'rejected') {
					return
				}
				const childRows = getChildRows({ tree: apiRef.current.state.rows.tree, rowId: groupKey })
				await duplicateRows(childRows, {
					[viewGroup.groupColumnId]: newGroupName.name,
				})
			}
		} else {
			await duplicateRows([...selectedRows.keys()] as string[])
		}
		apiRef.current.setRowSelectionModel([])
	}

	const handleDeleteRecords = async () => {
		let rowsForDeletion = selectedRows
		if (hasCellsSelected) {
			rowsForDeletion = new Map(
				Object.keys(selectedCells).reduce((_acc: Map<GridRowId, GridValidRowModel>, key) => {
					const row = mappedRowData.get(key)
					if (!row) {
						return _acc
					}
					_acc.set(row._id, row)
					return _acc
				}, new Map()),
			)
		}
		await onDeleteRecords(rowsForDeletion)
		apiRef.current.setRowSelectionModel([])
		apiRef.current.setCellSelectionModel({})
	}

	const handlePasteFromClipboard = async () => {
		if (!table?.records) return
		const selectedCellsArray = apiRef.current.getSelectedCellsAsArray()
		const updatedRows = await handleSpecialPasteFromClipboard(table.records, selectedCellsArray)
		if (!updatedRows) {
			showSnackbar('No selected rows', { variant: 'error' })
		}
		updateMultipleRecords(updatedRows)
	}

	useEffect(() => {
		if (hasRowsSelected && hasCellsSelected) {
			apiRef.current.setCellSelectionModel({})
		}
	}, [hasCellsSelected, hasRowsSelected])

	const rowSelectedPosition = useMemo(() => {
		return table?.records.findIndex(({ _id }) => selectedRows.has(_id) || selectedCells[_id])
	}, [table?.records, selectedRows])

	return (
		<>
			{NewNameDialog}
			<Button
				aria-controls={open ? 'actions-menu-options' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : 'false'}
				variant="text"
				disabled={!hasRowsSelected && !hasCellsSelected}
				onClick={handleClick}
			>
				Actions
			</Button>
			<Menu
				id="actions-menu-options"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				onClick={handleClose}
			>
				{(!permittedFunctions ||
					permittedFunctions.includes(TableTypes.ActionFunction.ADD_ROW_ABOVE)) &&
					selectedRows.size === 1 && (
						<MenuItem
							onClick={() => {
								if (_isNumber(rowSelectedPosition)) {
									handleAddRow(rowSelectedPosition)
								}
							}}
						>
							<ListItemIcon>
								<KeyboardArrowUpOutlinedIcon />
							</ListItemIcon>
							<ListItemText>Add row above</ListItemText>
						</MenuItem>
					)}
				{(!permittedFunctions ||
					permittedFunctions.includes(TableTypes.ActionFunction.ADD_ROW_BELOW)) &&
					selectedRows.size === 1 && (
						<MenuItem
							onClick={() => {
								if (_isNumber(rowSelectedPosition)) {
									handleAddRow(rowSelectedPosition + 1)
								}
							}}
						>
							<ListItemIcon>
								<KeyboardArrowDownOutlinedIcon />
							</ListItemIcon>
							<ListItemText>Add row below</ListItemText>
						</MenuItem>
					)}
				{(!permittedFunctions ||
					permittedFunctions.includes(TableTypes.ActionFunction.DUPLICATE_ROW)) &&
					hasRowsSelected && (
						<MenuItem onClick={handleDuplicateRows}>
							<ListItemIcon>
								<ContentCopyOutlinedIcon />
							</ListItemIcon>
							<ListItemText>Duplicate row(s)</ListItemText>
						</MenuItem>
					)}

				{(!permittedFunctions ||
					permittedFunctions.includes(TableTypes.ActionFunction.DELETE_ROW)) && (
					<MenuItem>
						<ListItemIcon>
							<DeleteIcon />
						</ListItemIcon>
						<ListItemText onClick={handleDeleteRecords}>Delete row(s)</ListItemText>
					</MenuItem>
				)}
				{!permittedFunctions && (
					<MenuItem>
						<ListItemIcon></ListItemIcon>
						<ListItemText onClick={handlePasteFromClipboard}>Paste from clipboard</ListItemText>
					</MenuItem>
				)}
			</Menu>
		</>
	)
}
