import { ComponentType, useCallback, useContext, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TablesSdk } from '@cango-app/sdk'
import { useSelector } from 'react-redux'

import { Box, Select } from '../../../../components'
import { SingleSelectForm } from '../types'
import { showSnackbar } from '../../../../helpers/snackbarManager'
import { TableContext } from '../../../../providers/table-provider'
import { selectors as authSelectors } from '../../../../store/modules/auth'

export const LookupOptions: ComponentType = () => {
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const { control, watch } = useFormContext<SingleSelectForm>()
	const { table, tableList } = useContext(TableContext)
	const [columnOptions, setColumnOptions] = useState<{ _id: string; label: string }[]>([])
	const lookupSelected = watch('single_select_lookup')

	const fetchColumnOptions = useCallback(async () => {
		if (!lookupSelected?.tableId) {
			setColumnOptions([])
			return
		}

		if (lookupSelected.tableId === table?._id) {
			setColumnOptions(
				table.fields.map((_field) => ({
					_id: _field._id,
					label: _field.name,
				})),
			)
			return
		}

		try {
			const response = await TablesSdk.getTable(
				import.meta.env.VITE_API as string,
				authHeaders,
				lookupSelected.tableId,
			)
			setColumnOptions(
				response.table.fields.map((_field) => ({
					_id: _field._id,
					label: _field.name,
				})),
			)
			if (response.circular_reference) {
				showSnackbar(`Circular reference detected\n${response.circular_reference}`, {
					variant: 'error',
					style: { whiteSpace: 'pre-line' },
				})
			}
			return
		} catch (error) {
			showSnackbar('Could not fetch table fields', { variant: 'error' })
			setColumnOptions([])
		}
	}, [lookupSelected?.tableId, table])

	useEffect(() => {
		fetchColumnOptions()
	}, [])

	return (
		<Box>
			<Controller
				control={control}
				name={'single_select_lookup.tableId'}
				render={({ field: { value, onChange } }) => (
					<Select
						options={tableList.map((_item) => ({
							..._item,
							label: _item.name,
						}))}
						value={value}
						onChange={onChange}
						label="Table"
					/>
				)}
			/>
			<Controller
				control={control}
				name={'single_select_lookup.fieldId'}
				render={({ field: { value, onChange } }) => (
					<Select options={columnOptions} value={value} onChange={onChange} label="Column" />
				)}
			/>
		</Box>
	)
}
