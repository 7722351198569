/* eslint-disable @typescript-eslint/no-empty-function */
import {
	ComponentType,
	createContext,
	MutableRefObject,
	PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError, TablesSdk } from '@cango-app/sdk'
import _forEach from 'lodash/forEach'
import _map from 'lodash/map'
import { TableTypes } from '@cango-app/types'
import {
	GridRowId,
	GridRowOrderChangeParams,
	GridSortModel,
	GridValidRowModel,
	useGridApiRef,
} from '@mui/x-data-grid-premium'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import _isNumber from 'lodash/isNumber'
import { createSelector } from 'reselect'
import { Edge } from 'reactflow'

import { selectors as authSelectors } from 'src/store/modules/auth'
import { errorHandler } from 'src/helpers/api'
import {
	getLinkedTable,
	resolveAnyRowCalculations,
	ResolvedRowData,
} from 'src/modules/tables/utils'
import { showSnackbar } from 'src/helpers/snackbarManager'

import { detectCycles } from '../modules/chains/get-element-layout'
import { LinkedTable, MenuTableWithLinkedTables } from '../modules/tables/types'

import { snackbarActions } from './snackbar-actions'
import { getObjectDifferences } from './utils'

type TableProviderProps = {
	tableId?: string
}

export type TaskProviderChildProps = {
	table: TableTypes.CangoTable | undefined
	isLoadingTable: boolean
	saveChanges: () => Promise<void>
	isUpdatingTable: boolean
	cacheNewRow: (
		newRow: GridValidRowModel,
		calculations: {
			[columnId: string]: TableTypes.FormulaSlice[]
		},
	) => ResolvedRowData
	cacheRowUpdate: (data: {
		newRow: GridValidRowModel
		oldRow: GridValidRowModel
		newCalculations?: { [columnId: string]: TableTypes.FormulaSlice[] }
		oldCalculations?: { [columnId: string]: TableTypes.FormulaSlice[] }
		updateRow?: boolean
	}) => Promise<GridValidRowModel>
	cacheMultipleRowUpdates: (data: {
		updatedRows: {
			newRow: GridValidRowModel
			oldRow: GridValidRowModel
		}[]
		updatedCalculations?: {
			rowId: string
			new: { [columnId: string]: TableTypes.FormulaSlice[] }
			old: { [columnId: string]: TableTypes.FormulaSlice[] }
		}[]
		updateTable?: boolean
		updatedOverrides?: { [rowId: string]: { [columnId: string]: string | number | undefined } }
	}) => Promise<GridValidRowModel[]>
	unsavedChanges: TableUnsavedChanges
	discardChange: (id: string) => void
	discardAllChanges: () => void
	onRowOrderChange: (params: GridRowOrderChangeParams) => void
	onAddRow: (data?: { position?: number; parentId?: string }) => Promise<void>
	onAddColumn: () => Promise<void>
	columnList: { _id: string; label: string }[]
	mappedColumns: Map<string, TableTypes.Field>
	mappedRowData: Map<string, TableTypes.Record['data'] & { _id: string }>
	mappedRecords: Map<string, TableTypes.Record>
	onUpdateColumn: (
		fieldId: string,
		updates: Omit<TablesSdk.UpdateFieldRequest, 'tableId'>,
	) => Promise<{ result: 'success' | 'error' }>
	onDeleteColumn: (fieldId: string) => Promise<void>
	saveContents: (
		rowId: string,
		row: GridValidRowModel,
		fieldId: string,
		contents: any,
	) => Promise<void>
	duplicateRows: (
		selectedRows: string[],
		newGroupValues?: {
			[columnId: string]: any
		},
	) => Promise<void>
	onDeleteRecords: (selectedRows: Map<GridRowId, GridValidRowModel>) => Promise<void>
	saveViews: (data: TableTypes.TableView[]) => void
	onDeleteView: (viewId: string) => Promise<void>
	updateTableConfig: (
		data: TablesSdk.UpdateTableConfigRequest,
		options?: { updateDb?: boolean },
	) => Promise<void>
	apiRef: MutableRefObject<GridApiPremium>
	sortingModel: GridSortModel
	updateSortingModel: (data: GridSortModel) => Promise<void>
	updateDecendants: ({
		rowId,
		descendants,
	}: {
		rowId: string
		descendants: TableTypes.Descendant[]
	}) => Promise<void>
	updateMultipleRecords: (updatedRecords: TableTypes.Record[]) => Promise<void>
	// table browser
	tableList: MenuTableWithLinkedTables[]
	setTableList: React.Dispatch<React.SetStateAction<MenuTableWithLinkedTables[]>>
	isFetchingTableList: boolean
	associatedQuestionaireId: string
	updateTableListItem: (tableId: string, data: Partial<TablesSdk.MenuTable>) => Promise<void>
	linkedTable: LinkedTable | undefined
	fetchTable: (tableId: string, forceFetch?: boolean) => Promise<void>
}

type RowCalculations = { [rowId: string]: { [columnId: string]: TableTypes.FormulaSlice[] } }
type Overrides = { [rowId: string]: { [columnId: string]: string | number | undefined } }

type TableUnsavedChanges = {
	unsavedRows: Record<string, GridValidRowModel>
	rowsBeforeChange: Record<string, GridValidRowModel>
	newRows: Record<string, GridValidRowModel>
	calculations: {
		unsavedCalculations: RowCalculations
		calculationsBeforeChange: RowCalculations
		newCalculations: RowCalculations
	}
	overrides: Overrides
}

const initialUnsavedChanges: TableUnsavedChanges = {
	unsavedRows: {},
	rowsBeforeChange: {},
	newRows: {},
	calculations: {
		unsavedCalculations: {},
		calculationsBeforeChange: {},
		newCalculations: {},
	},
	overrides: {},
}

export const TableContext = createContext<TaskProviderChildProps>({
	table: undefined,
	isLoadingTable: false,
	saveChanges: async () => {},
	isUpdatingTable: false,
	cacheMultipleRowUpdates: async () => [],
	cacheRowUpdate: async () => ({}),
	unsavedChanges: initialUnsavedChanges,
	discardChange: () => {},
	discardAllChanges: () => {},
	onRowOrderChange: () => {},
	onAddRow: async () => {},
	onAddColumn: async () => {},
	columnList: [],
	mappedColumns: new Map(),
	mappedRecords: new Map(),
	mappedRowData: new Map(),
	onUpdateColumn: async () => {
		return { result: 'success' }
	},
	onDeleteColumn: async () => {},
	cacheNewRow: () => ({ _id: 'test' }),
	saveContents: async () => {},
	duplicateRows: async () => {},
	onDeleteRecords: async () => {},
	saveViews: () => {},
	onDeleteView: async () => {},
	updateTableConfig: async () => {},
	apiRef: { current: {} as GridApiPremium },
	updateSortingModel: async () => {},
	sortingModel: [],
	updateDecendants: async () => {},
	updateMultipleRecords: async () => {},
	associatedQuestionaireId: '',

	// table browser
	tableList: [],
	setTableList: () => {},
	isFetchingTableList: false,
	updateTableListItem: async () => {},
	linkedTable: undefined,
	fetchTable: async () => {},
})

const getColumnList = createSelector(
	(fields: TableTypes.Field[]) => fields,
	(fields) => {
		return fields.map(({ _id, name }) => ({
			_id,
			label: name,
		}))
	},
)

const getAssociatedQuestionaireId = createSelector(
	(fields: TableTypes.Field[]) => fields,
	(fields) => {
		const questionaireColumn = fields.find(
			({ type }) => type === TableTypes.FieldType.QUESTIONAIRE_REFERENCE,
		)
		return questionaireColumn?.reference ?? ''
	},
)

export const TableProvider: ComponentType<PropsWithChildren<TableProviderProps>> = (props) => {
	const dispatch = useDispatch()
	const apiRef = useGridApiRef()
	const [isLoadingTable, setIsLoadingTable] = useState(false)
	const [isUpdatingTable, setIsUpdatingTable] = useState(false)
	const [table, setTable] = useState<TableTypes.CangoTable | undefined>()
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [unsavedChanges, setUnsavedChanges] = useState<TableUnsavedChanges>(initialUnsavedChanges)

	const [tableList, setTableList] = useState<MenuTableWithLinkedTables[]>([])
	const [isFetchingTableList, setIsFetchingTableList] = useState(false)

	// table browser start--------------------------------

	const tableId = useMemo(() => props.tableId, [props.tableId])

	const showCircularReferenceWarning = (circularReference: string) => {
		showSnackbar(`Circular reference detected\n${circularReference}`, {
			variant: 'error',
			autoHideDuration: 10000,
			action: snackbarActions,
			style: { whiteSpace: 'pre-line' },
		})
	}

	const linkedQuestionaire = tableList.find(({ _id }) => _id === tableId)?.linkedTable

	const fetchTableList = useCallback(async () => {
		try {
			setIsFetchingTableList(true)
			const response = await TablesSdk.getTables(import.meta.env.VITE_API as string, authHeaders)
			setTableList(
				response.map((_table) => ({
					..._table,
					linkedTable: getLinkedTable(_table, response),
				})),
			)
		} catch (error) {
			showSnackbar('Could not fetch tables', { variant: 'error' })
		} finally {
			setIsFetchingTableList(false)
		}
	}, [authHeaders])

	useEffect(() => {
		fetchTableList()
	}, [])

	// table broswer end --------------------------------

	const mappedColumns = useMemo(() => {
		if (!table?.fields) return new Map()
		return new Map(table.fields.map((_field) => [_field._id, _field]))
	}, [table?.fields])

	const mappedRecords: Map<string, TableTypes.Record> = useMemo(() => {
		if (!table?.records) return new Map()
		return new Map(table.records.map((_row) => [_row._id, { ..._row, _id: _row._id }]))
	}, [table?.records])

	const mappedRowData: Map<string, TableTypes.Record['data'] & { _id: string }> = useMemo(() => {
		if (!table?.records) return new Map()
		return new Map(table.records.map((_row) => [_row._id, { ..._row.data, _id: _row._id }]))
	}, [table?.records])

	const saveChanges = async () => {
		if (!table) return
		try {
			setIsUpdatingTable(true)
			const updatedRecords = _map(
				unsavedChanges.unsavedRows,
				({ isNew, _id, ...row }, id): TablesSdk.UpdateRecord => {
					const originalRecord = table.records.find((_record) => _record._id === id)
					return {
						_id: id,
						data: row,
						calculations: unsavedChanges.calculations.unsavedCalculations[id],
						overrides: {
							...(originalRecord?.overrides ?? {}),
							...(unsavedChanges.overrides[id] ?? {}),
						},
					}
				},
			)

			const newRows = _map(unsavedChanges.newRows, (row, id): TablesSdk.UpdateRecord => {
				return {
					_id: id,
					data: row,
					calculations: unsavedChanges.calculations.newCalculations[id],
				}
			})

			const response = await TablesSdk.updateRecords(
				import.meta.env.VITE_API as string,
				authHeaders,
				{
					records: updatedRecords,
					newRecords: newRows,
					tableId: table._id,
				},
			)
			setTable(response.table)
			if (response.circular_reference) {
				showCircularReferenceWarning(response.circular_reference)
			}

			setUnsavedChanges({
				unsavedRows: {},
				rowsBeforeChange: {},
				newRows: {},
				calculations: {
					unsavedCalculations: {},
					calculationsBeforeChange: {},
					newCalculations: {},
				},
				overrides: {},
			})
		} catch (error) {
			errorHandler({ error, dispatch, message: 'Could not update table' })
		} finally {
			setIsUpdatingTable(false)
		}
	}

	const updateMultipleRecords = async (updatedRecords: TableTypes.Record[]) => {
		if (!table) return
		setIsUpdatingTable(true)
		try {
			const response = await TablesSdk.updateRecords(
				import.meta.env.VITE_API as string,
				authHeaders,
				{
					records: updatedRecords,
					newRecords: [],
					tableId: table._id,
				},
			)
			setTable(response.table)
			if (response.circular_reference) {
				showCircularReferenceWarning(response.circular_reference)
			}
		} catch (error) {
			showSnackbar('Error updating records', { variant: 'error' })
		} finally {
			setIsUpdatingTable(false)
		}
	}

	const updateDecendants = useCallback(
		async ({ rowId, descendants }: { rowId: string; descendants: TableTypes.Descendant[] }) => {
			if (!table?._id) {
				return
			}
			const { previousDescendants, newDescendants } = table.records.reduce(
				(
					acc: {
						previousDescendants?: TableTypes.Descendant[]
						newDescendants: TableTypes.Descendant[]
					},
					_record,
				) => {
					if (_record._id !== rowId) {
						return acc
					}
					const previousDescendants = _record.descendants
					return {
						previousDescendants,
						newDescendants: descendants,
					}
				},
				{
					previousDescendants: [],
					newDescendants: descendants,
				},
			)
			try {
				const updatedTable: TableTypes.CangoTable = {
					...table,
					records: table.records.map((_record) => {
						if (_record._id !== rowId) {
							return _record
						}
						return {
							..._record,
							descendants: newDescendants,
						}
					}),
				}

				const newEdges = updatedTable.records.reduce((_acc: Edge[], _record) => {
					const recordDependants = _record.descendants ?? []
					const edges = recordDependants.reduce((_acc: Edge[], _child) => {
						if (
							_acc.find((_edge) => _edge.source === _record._id && _edge.target === _child.row) ||
							!_child.row
						) {
							return _acc
						}

						_acc.push({
							id: `e${_record._id}--${_child.row}`,
							source: _record._id,
							target: _child.row,
						})

						return _acc
					}, [])
					_acc.push(...edges)
					return _acc
				}, [])

				const hasCycles = detectCycles(newEdges)

				if (hasCycles.length) {
					throw new Error('Circular reference detected')
				}

				setTable((prev) => {
					if (!prev) {
						return prev
					}
					return updatedTable
				})

				await TablesSdk.updateRecords(import.meta.env.VITE_API as string, authHeaders, {
					tableId: table._id,
					records: [
						{
							_id: rowId,
							descendants,
						},
					],
					newRecords: [],
				})
			} catch (error) {
				const errorMessage = (error as Error).message ?? 'Error updating dependencies'
				showSnackbar(errorMessage, { variant: 'error' })
				setTable((prev) => {
					if (!prev) {
						return prev
					}
					return {
						...prev,
						records: prev.records.map((_record) => {
							if (_record._id !== rowId) {
								return _record
							}
							return {
								..._record,
								descendants: previousDescendants,
							}
						}),
					}
				})
			}
		},
		[table, authHeaders],
	)

	const cacheNewRow = useCallback(
		(
			newRow: GridValidRowModel,
			calculations: { [columnId: string]: TableTypes.FormulaSlice[] },
		) => {
			if (!table) {
				return { ...newRow, _id: newRow.id }
			}
			const isLockedMap = new Map(
				table.fields.map((_field) => [
					_field._id,
					_field.locked || _field.type === TableTypes.FieldType.CALCULATION,
				]),
			)
			table.fields.forEach((field) => {
				if (field.calculation) {
					const hasVlookup = field.calculation.some(
						({ type }) => type === TableTypes.FormulaSliceType.VLOOKUP,
					)
					hasVlookup
						? showSnackbar(`Vlookup values are updated when saving the database`, {
								variant: 'warning',
							})
						: null
				}
			})

			_forEach(newRow, (value, id) => {
				const isLocked = isLockedMap.get(id)
				let newValue = value
				if (isLocked) {
					newValue = undefined
				}

				newRow[id] = newValue
			})

			const resolved = resolveAnyRowCalculations({
				fields: table.fields,
				row: {
					_id: newRow._id,
					data: newRow,
					references: {},
				},
				referenceColumns: table.referenceColumnNames,
				questionaireAnswers: table.questionaire_answers ?? [],
			})

			setUnsavedChanges((prev) => {
				const rowId = resolved._id
				const unsavedChangesCopy = { ...prev }
				unsavedChangesCopy.newRows[rowId] = resolved
				unsavedChangesCopy.calculations.newCalculations[rowId] = calculations
				return unsavedChangesCopy
			})

			return resolved
		},
		[table],
	)

	const saveContents = useCallback(
		async (rowId: string, row: GridValidRowModel, fieldId: string, newContents: any) => {
			if (!table) return
			try {
				setIsUpdatingTable(true)
				const response = await TablesSdk.updateRecords(
					import.meta.env.VITE_API as string,
					authHeaders,

					{
						tableId: table._id,
						records: [
							{
								_id: rowId,
								data: {
									...row,
									[fieldId]: newContents as any,
								},
							},
						],
						newRecords: [],
					},
				)
				setTable(response.table)
				if (response.circular_reference) {
					showCircularReferenceWarning(response.circular_reference)
				}
			} catch (error) {
				errorHandler({ error, dispatch, message: 'Could not update contents' })
			} finally {
				setIsUpdatingTable(false)
			}
		},
		[table, authHeaders],
	)

	const cacheMultipleRowUpdates = async ({
		updatedRows,
		updateTable,
		updatedCalculations,
		updatedOverrides,
	}: {
		updatedRows: {
			newRow: GridValidRowModel
			oldRow: GridValidRowModel
		}[]
		updatedCalculations?: {
			rowId: string
			new: Record<string, TableTypes.FormulaSlice[]>
			old: Record<string, TableTypes.FormulaSlice[]>
		}[]
		updateTable?: boolean
		updatedOverrides?: { [rowId: string]: { [columnId: string]: string | number | undefined } }
	}): Promise<GridValidRowModel[]> => {
		if (!table) {
			return updatedRows.map(({ oldRow }) => oldRow)
		}
		const isLockedMap = new Map(
			table.fields.map((_field) => [
				_field._id,
				_field.locked || _field.type === TableTypes.FieldType.CALCULATION,
			]),
		)

		const resolvedRows: GridValidRowModel[] = []
		for (const { newRow, oldRow } of updatedRows) {
			const tableRecord = table.records.find((_record) => _record._id === newRow._id)

			if (!tableRecord) {
				resolvedRows.push(oldRow)
				continue
			}

			table.fields.forEach((field) => {
				if (field.calculation) {
					const foundVlookups = field.calculation.filter(
						({ type }) => type === TableTypes.FormulaSliceType.VLOOKUP,
					)
					if (foundVlookups) {
						foundVlookups.forEach(({ vlookup }) => {
							if (vlookup) {
								const differences = getObjectDifferences(newRow, oldRow)
								Object.values(vlookup).forEach((vlookupKey) => {
									if (Object.keys(differences).includes(vlookupKey as string)) {
										showSnackbar('Vlookup values are updated after saving database', {
											variant: 'warning',
										})
									}
								})
							}
						})
					}
				}
			})

			_forEach(newRow, (value, id) => {
				const isLocked = isLockedMap.get(id)
				let newValue = value
				if (isLocked) {
					newValue = tableRecord.data[id]
				}

				newRow[id] = newValue
			})

			const resolved = resolveAnyRowCalculations({
				fields: table.fields,
				row: {
					_id: newRow._id,
					data: newRow,
					references: {},
				},
				referenceColumns: table.referenceColumnNames,
				questionaireAnswers: table.questionaire_answers ?? [],
			})
			resolvedRows.push(resolved)
		}
		const processedUpdatedRows = new Map(resolvedRows.map((row) => [row._id as string, row]))

		setUnsavedChanges((prev) => {
			const rows = resolvedRows.reduce(
				(_resolvedRows: Pick<TableUnsavedChanges, 'unsavedRows' | 'rowsBeforeChange'>, row) => {
					const rowId = row._id
					_resolvedRows.unsavedRows[rowId] = row
					const oldRow = updatedRows.find(({ newRow }) => newRow._id === rowId)?.oldRow
					if (!_resolvedRows.rowsBeforeChange[rowId] && oldRow) {
						_resolvedRows.rowsBeforeChange[rowId] = oldRow
					}
					return _resolvedRows
				},
				{
					unsavedRows: prev.unsavedRows,
					rowsBeforeChange: prev.rowsBeforeChange,
				},
			)

			if (updatedCalculations) {
				updatedCalculations.forEach(({ rowId, new: newCalcs, old: oldCalcs }) => {
					if (!prev.calculations.calculationsBeforeChange[rowId]) {
						prev.calculations.calculationsBeforeChange[rowId] = oldCalcs
					}
					prev.calculations.unsavedCalculations[rowId] = Object.keys(newCalcs).reduce(
						(
							acc: {
								[columnId: string]: TableTypes.FormulaSlice[]
							},
							key,
						) => {
							if (newCalcs[key].length) {
								acc[key] = newCalcs[key]
							}
							return acc
						},
						{},
					)
				})
			}

			if (updatedOverrides) {
				_forEach(updatedOverrides, (overrides, rowId) => {
					if (!prev.overrides[rowId]) {
						prev.overrides[rowId] = {}
					}
					_forEach(overrides, (value, columnId) => {
						prev.overrides[rowId][columnId] = value
					})
				})
			}

			return { ...prev, ...rows }
		})

		if (updateTable) {
			setTable((prevValue) => {
				if (!prevValue) {
					return
				}
				return {
					...prevValue,
					records: prevValue.records.map((record) => {
						const updatedRow = processedUpdatedRows.get(record._id)
						if (updatedRow) {
							return {
								...record,
								_id: record._id,
								data: updatedRow,
							}
						}
						return record
					}),
				}
			})
		}

		return resolvedRows
	}

	const cacheRowUpdate = async ({
		newRow,
		oldRow,
		updateRow,
		newCalculations,
		oldCalculations,
	}: {
		newRow: GridValidRowModel
		oldRow: GridValidRowModel
		newCalculations?: Record<string, TableTypes.FormulaSlice[]>
		oldCalculations?: Record<string, TableTypes.FormulaSlice[]>
		updateRow?: boolean
	}): Promise<GridValidRowModel> => {
		if (!table) return oldRow
		const isLockedMap = new Map(table.fields.map((_field) => [_field._id, _field.locked]))
		const tableRecord = table.records.find((_record) => _record._id === newRow._id)

		if (!tableRecord) {
			return oldRow
		}

		table.fields.forEach((field) => {
			if (field.calculation) {
				const foundVlookups = field.calculation.filter(
					({ type }) => type === TableTypes.FormulaSliceType.VLOOKUP,
				)
				if (foundVlookups) {
					foundVlookups.forEach(({ vlookup }) => {
						if (vlookup) {
							const differences = getObjectDifferences(newRow, oldRow)
							Object.values(vlookup).forEach((vlookupKey) => {
								if (Object.keys(differences).includes(vlookupKey as string)) {
									showSnackbar('Vlookup values are updated after saving database', {
										variant: 'warning',
									})
								}
							})
						}
					})
				}
			}
		})

		_forEach(newRow, (value, id) => {
			const isLocked = isLockedMap.get(id)
			let newValue = value
			if (isLocked) {
				newValue = tableRecord.data[id]
			}

			newRow[id] = newValue
		})

		const resolved = resolveAnyRowCalculations({
			fields: table.fields,
			row: {
				_id: newRow._id,
				data: newRow,
				references: {},
			},
			referenceColumns: table.referenceColumnNames,
			questionaireAnswers: table.questionaire_answers ?? [],
		})

		setUnsavedChanges((prev) => {
			const rowId = resolved._id
			const unsavedChangesCopy = { ...prev }
			unsavedChangesCopy.unsavedRows[rowId] = resolved
			if (!unsavedChangesCopy.rowsBeforeChange[rowId]) {
				unsavedChangesCopy.rowsBeforeChange[rowId] = oldRow
			}

			table.fields.forEach(({ type, _id }) => {
				if ([TableTypes.FieldType.CALCULATION].includes(type)) {
					if (
						unsavedChangesCopy.rowsBeforeChange[rowId][_id] !==
						unsavedChangesCopy.unsavedRows[rowId][_id]
					) {
						if (!unsavedChangesCopy.overrides[rowId]) {
							unsavedChangesCopy.overrides[rowId] = {}
						}
						unsavedChangesCopy.overrides[rowId][_id] = unsavedChangesCopy.unsavedRows[rowId][_id]
					}
				}
			})

			if (oldCalculations && !unsavedChangesCopy.calculations.calculationsBeforeChange[rowId]) {
				unsavedChangesCopy.calculations.calculationsBeforeChange[rowId] = oldCalculations
			}

			if (newCalculations) {
				unsavedChangesCopy.calculations.unsavedCalculations[rowId] = Object.keys(
					newCalculations,
				).reduce(
					(
						acc: {
							[columnId: string]: TableTypes.FormulaSlice[]
						},
						key,
					) => {
						if (newCalculations[key].length) {
							acc[key] = newCalculations[key]
						}
						return acc
					},
					{},
				)
			}

			return unsavedChangesCopy
		})

		if (updateRow) {
			setTable((prevValue) => {
				if (!prevValue) {
					return
				}
				return {
					...prevValue,
					records: prevValue.records.map((record) => {
						if (record._id === newRow._id) {
							return {
								...record,
								_id: record._id,
								data: newRow,
							}
						}
						return record
					}),
				}
			})
		}

		return resolved
	}

	const updateTableListItem = useCallback(
		async (tableId: string, data: Partial<TablesSdk.MenuTable>) => {
			const previousTableList = [...tableList]
			try {
				const copiedTableList = [...tableList].map((table) => {
					if (table._id === tableId) {
						return {
							...table,
							...data,
						}
					}
					return table
				})
				setTableList(copiedTableList)
				await TablesSdk.updateTableConfig(
					import.meta.env.VITE_API as string,
					authHeaders,
					tableId,
					data,
				)
			} catch (error) {
				setTableList(previousTableList)
				showSnackbar('Error updating table', { variant: 'error' })
			}
		},
		[authHeaders, tableList, table],
	)

	const discardChange = useCallback((id: string) => {
		setUnsavedChanges((prev) => {
			const unsavedChangesCopy = { ...prev }
			delete unsavedChangesCopy.unsavedRows[id]
			delete unsavedChangesCopy.rowsBeforeChange[id]
			delete unsavedChangesCopy.newRows[id]
			return unsavedChangesCopy
		})
	}, [])

	const discardAllChanges = useCallback(() => {
		setUnsavedChanges({
			unsavedRows: {},
			rowsBeforeChange: {},
			newRows: {},
			calculations: {
				unsavedCalculations: {},
				calculationsBeforeChange: {},
				newCalculations: {},
			},
			overrides: {},
		})
	}, [])

	const onDeleteRecords = useCallback(
		async (selectedRows: Map<GridRowId, GridValidRowModel>) => {
			if (!table) return
			const previousTable = { ...table }
			const recordUpdates = table.records.reduce<TablesSdk.UpdateRecordsRequest['records']>(
				(acc, record) => {
					if (record.descendants?.some(({ row }) => row && selectedRows.has(row))) {
						return [
							...acc,
							{
								_id: record._id,
								descendants: record.descendants.filter(({ row }) => row && !selectedRows.has(row)),
							},
						]
					}

					if (!selectedRows.has(record._id)) {
						return acc
					}
					return [
						...acc,
						{
							_id: record._id,
							data: {
								...record.data,
								_action: 'delete',
							},
							references: {},
						},
					]
				},
				[],
			)
			setTable((prev) => {
				if (!prev) return prev
				return {
					...prev,
					records: prev.records.reduce((_acc: TableTypes.Record[], _record) => {
						if (selectedRows.has(_record._id)) {
							return _acc
						}
						if (_record.descendants?.some(({ row }) => row && selectedRows.has(row))) {
							return [
								..._acc,
								{
									..._record,
									descendants: _record.descendants.filter(
										({ row }) => row && !selectedRows.has(row),
									),
								},
							]
						}
						return [..._acc, _record]
					}, []),
				}
			})
			setIsUpdatingTable(true)
			try {
				const response = await TablesSdk.updateRecords(
					import.meta.env.VITE_API as string,
					authHeaders,

					{
						tableId: table._id,
						newRecords: [],
						records: recordUpdates,
					},
				)
				if (response.circular_reference) {
					showCircularReferenceWarning(response.circular_reference)
				}
			} catch (err) {
				showSnackbar('Error deleting records', { variant: 'error' })
				setTable(previousTable)
			} finally {
				setIsUpdatingTable(false)
			}
		},
		[table, authHeaders],
	)

	const handleSaveViews = useCallback((views: TableTypes.TableView[]) => {
		setTable((prevValue) => {
			if (!prevValue) {
				return
			}
			return {
				...prevValue,
				views,
			}
		})
	}, [])

	const updateTableConfig = useCallback(
		async (data: TablesSdk.UpdateTableConfigRequest, options?: { updateDb?: boolean }) => {
			if (!table) return
			const { updateDb = true } = options ?? {}
			setIsUpdatingTable(true)
			try {
				let response: TablesSdk.UpdateTableConfigResponse | undefined
				if (updateDb) {
					response = await TablesSdk.updateTableConfig(
						import.meta.env.VITE_API as string,
						authHeaders,
						table._id,
						data,
					)
				}
				setTable((prev) => {
					if (!prev) return prev

					return {
						...prev,
						...data,
						...(response ?? {}),
					}
				})
				if (data.type !== undefined) {
					setTableList((prev) => {
						return prev.map((_table) => {
							if (_table._id === table._id && data.type) {
								return {
									..._table,
									type: data.type,
								}
							}
							return _table
						})
					})
				}
			} catch (err) {
				showSnackbar('Error updating table', { variant: 'error' })
			} finally {
				setIsUpdatingTable(false)
			}
		},
		[table, authHeaders],
	)

	const handleAddRow = useCallback(
		async (data?: { position?: number; parentId?: string }) => {
			if (!table) return
			const { position, parentId } = data ?? {}
			setIsUpdatingTable(true)
			try {
				const { newRow, newDescendant } = await TablesSdk.addRow(
					import.meta.env.VITE_API as string,
					authHeaders,
					table._id,
					{
						position,
						parentId,
					},
				)
				setTable((prev) => {
					if (!prev) return prev
					return {
						...prev,
						records: [
							...prev.records.map((_record) => {
								if (_record._id === parentId && newDescendant) {
									return {
										..._record,
										descendants: [...(_record.descendants ?? []), newDescendant],
									}
								}
								return _record
							}),
							newRow,
						],
					}
				})
			} catch (error) {
				errorHandler({ error, dispatch, message: 'Could not add row' })
			} finally {
				setIsUpdatingTable(false)
			}
		},
		[table, authHeaders],
	)

	const handleAddColumn = useCallback(async () => {
		if (!table) return
		setIsUpdatingTable(true)
		try {
			const response = await TablesSdk.addColumn(import.meta.env.VITE_API as string, authHeaders, {
				tableId: table._id,
			})
			setTable((prev) => {
				if (!prev) return prev
				return {
					...prev,
					fields: [...prev.fields, response.column],
					column_order: response.column_order,
				}
			})
		} catch (error) {
			let errorMessage = 'Could not add column'
			if ((error as AxiosError<{ message?: string }>).response?.data?.message) {
				errorMessage = (error as AxiosError<{ message?: string }>).response?.data?.message as string
			}
			errorHandler({ error, dispatch, message: errorMessage })
		} finally {
			setIsUpdatingTable(false)
		}
	}, [table?._id, authHeaders])

	const handleDeleteColumn = useCallback(
		async (fieldId: string) => {
			if (!table) return
			setIsUpdatingTable(true)
			try {
				await TablesSdk.deleteField(import.meta.env.VITE_API as string, authHeaders, {
					tableId: table._id,
					fieldId,
				})
				setTable((currentTable) => {
					if (!currentTable) return
					return {
						...currentTable,
						fields: currentTable.fields.filter(({ _id }) => fieldId !== _id),
					}
				})
			} catch (error) {
				errorHandler({ error, dispatch, message: 'Could not delete column' })
			} finally {
				setIsUpdatingTable(false)
			}
		},
		[table?._id, authHeaders],
	)

	const handleUpdateColumn = useCallback(
		async (
			fieldId: string,
			updates: Omit<TablesSdk.UpdateFieldRequest, 'tableId'>,
		): Promise<{ result: 'success' | 'error' }> => {
			if (!table) return { result: 'error' }
			setIsUpdatingTable(true)
			try {
				const column = mappedColumns.get(fieldId)
				if (_isNumber(updates.width) && updates.width === column?.width) {
					return { result: 'error' }
				}
				const response = await TablesSdk.updateField(
					import.meta.env.VITE_API as string,
					authHeaders,
					table._id,
					fieldId,
					updates,
				)
				setTable(response.table)
				if (response.circular_reference) {
					showCircularReferenceWarning(response.circular_reference)
				}
				showSnackbar('Column updated', { variant: 'success' })
				setIsUpdatingTable(false)
				return { result: 'success' }
			} catch (error) {
				errorHandler({ error, dispatch, message: 'Could not update column' })
				setIsUpdatingTable(false)
				return { result: 'error' }
			}
		},
		[table?._id, authHeaders],
	)

	const handleRowOrderChange = useCallback(async () => {
		if (!table) return
		setIsUpdatingTable(true)
		const previousTable = { ...table }
		try {
			const sortedRows = apiRef.current.getSortedRows()
			const sortedRowIds = sortedRows.map((_row) => _row._id as string)
			setTable((prev) => {
				if (!prev) {
					return prev
				}
				return {
					...table,
					row_order: sortedRowIds,
				}
			})
			await TablesSdk.updateTableConfig(
				import.meta.env.VITE_API as string,
				authHeaders,
				table._id,
				{
					row_order: sortedRowIds,
				},
			)
		} catch (error) {
			setTable(previousTable)
			errorHandler({ error, dispatch, message: 'Could not update column order' })
		} finally {
			setIsUpdatingTable(false)
		}
	}, [table?._id, authHeaders, apiRef])

	const fetchTable = useCallback(
		async (newTableId: string, forceFetch?: boolean) => {
			try {
				setIsLoadingTable(true)
				setTable(undefined)
				const response = await TablesSdk.getTable(
					import.meta.env.VITE_API as string,
					authHeaders,
					newTableId,
					{ force: forceFetch },
				)
				setTable(response.table)
				if (response.circular_reference) {
					showCircularReferenceWarning(response.circular_reference)
				}
			} catch (error) {
				if ((error as AxiosError).response?.status === 404) {
					showSnackbar('Table not found', { variant: 'error' })
					return
				}
				errorHandler({ error, dispatch, message: 'Could not fetch table' })
			} finally {
				setIsLoadingTable(false)
			}
		},
		[authHeaders],
	)

	const duplicateRows = async (
		selectedRowIds: string[],
		newGroupValues?: {
			[columnId: string]: any
		},
	) => {
		if (!table) return
		setIsUpdatingTable(true)
		try {
			const response = await TablesSdk.duplicateRows(
				import.meta.env.VITE_API as string,
				authHeaders,
				table._id,
				{
					duplicate: selectedRowIds,
					newGroupValues,
				},
			)
			setTable(response)
		} catch (err) {
			showSnackbar('Error duplicating rows', { variant: 'error' })
		} finally {
			setIsUpdatingTable(false)
		}
	}

	const updateSortingModel = useCallback(
		async (data: GridSortModel) => {
			if (!table) return
			setIsUpdatingTable(true)
			try {
				const updatedSortingModel = await TablesSdk.updateSorting(
					import.meta.env.VITE_API as string,
					authHeaders,
					table._id,
					{
						sortingModel: data,
					},
				)
				setTable((currentTable) => {
					if (!currentTable) return
					return {
						...currentTable,
						sortingModel: updatedSortingModel,
					}
				})
			} catch (err) {
				showSnackbar('Error sorting table', { variant: 'error' })
			} finally {
				setIsUpdatingTable(false)
			}
		},
		[authHeaders, table],
	)

	const sortingModel = useMemo(() => table?.sortingModel ?? [], [table])

	const onDeleteView = async (viewId: string) => {
		if (!table) return
		setIsUpdatingTable(true)
		try {
			await TablesSdk.deleteView(import.meta.env.VITE_API as string, authHeaders, table._id, viewId)
			setTable((currentTable) => {
				if (!currentTable) return
				return {
					...currentTable,
					views: currentTable.views.filter(({ _id }) => viewId !== _id),
				}
			})
		} catch (err) {
			showSnackbar('Error deleting view', { variant: 'error' })
		} finally {
			setIsUpdatingTable(false)
		}
	}

	useEffect(() => {
		if (tableId && table?._id !== tableId && !isLoadingTable) {
			setUnsavedChanges({
				unsavedRows: {},
				rowsBeforeChange: {},
				newRows: {},
				calculations: {
					unsavedCalculations: {},
					calculationsBeforeChange: {},
					newCalculations: {},
				},
				overrides: {},
			})
			fetchTable(tableId)
		}
	}, [tableId, table?._id, isLoadingTable])

	const contextValue = useMemo(
		(): Omit<TaskProviderChildProps, 'apiRef'> => ({
			table,
			isLoadingTable,
			saveChanges,
			isUpdatingTable,
			cacheRowUpdate,
			unsavedChanges,
			discardChange,
			discardAllChanges,
			onRowOrderChange: handleRowOrderChange,
			onAddRow: handleAddRow,
			onAddColumn: handleAddColumn,
			columnList: getColumnList(table?.fields ?? []),
			mappedColumns,
			mappedRowData,
			mappedRecords,
			onUpdateColumn: handleUpdateColumn,
			onDeleteColumn: handleDeleteColumn,
			cacheNewRow,
			saveContents,
			duplicateRows,
			onDeleteRecords,
			saveViews: handleSaveViews,
			onDeleteView,
			updateTableConfig,
			updateSortingModel,
			sortingModel,
			updateMultipleRecords,
			associatedQuestionaireId: getAssociatedQuestionaireId(table?.fields ?? []),

			// table browser
			tableList,
			setTableList,

			//loading states
			isFetchingTableList,
			cacheMultipleRowUpdates,
			updateDecendants,
			updateTableListItem,
			linkedTable: linkedQuestionaire,
			fetchTable,
		}),
		[
			table,
			isLoadingTable,
			isUpdatingTable,
			unsavedChanges,
			mappedColumns,
			mappedRowData,
			mappedRecords,
			sortingModel,
			tableList,
			isFetchingTableList,
			linkedQuestionaire,
			fetchTable,
		],
	)

	return (
		<TableContext.Provider value={{ ...contextValue, apiRef }}>
			{props.children}
		</TableContext.Provider>
	)
}
