import { Paper, Stack } from '@mui/material'
import { ComponentType, PropsWithChildren } from 'react'

import { Box } from '../box'

export const QuestionCard: ComponentType<PropsWithChildren> = ({ children }) => {
	return (
		<Stack direction="row" justifyContent="center">
			<Paper
				elevation={4}
				square={false}
				sx={{
					minWidth: 550,
				}}
			>
				<Box
					flex={1}
					display="flex"
					flexDirection="column"
					sx={{
						minHeight: 300,
					}}
					padding={2}
				>
					{children}
				</Box>
			</Paper>
		</Stack>
	)
}
