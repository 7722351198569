import React, { ComponentType, useContext, useState } from 'react'
import { V3ProjectSdk } from '@cango-app/sdk'
import { useDispatch, useSelector } from 'react-redux'

import { TaskContext } from 'src/providers/task-provider'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { actions as projectActions } from 'src/store/modules/projects-v3'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { useProject } from 'src/modules/projects-v3/useProject'

import { Box } from '../box'
import { Button } from '../button'

import { AddNote } from './add-note'
import { AllNotes } from './all-notes'

export const NotesContainer: ComponentType = () => {
	const dispatch = useDispatch()
	const [isResolving, setIsResolving] = useState(false)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const { task, updateTask } = useContext(TaskContext)
	const { goToTask } = useProject()
	const notes = task?.notes || []

	const handleAddNote = async (note: string) => {
		if (!task) return
		try {
			const newNote = await V3ProjectSdk.addNoteToTask(
				import.meta.env.VITE_API as string,
				authHeaders,
				{
					taskId: task._id,
					note,
					tempId: '',
				},
			)
			updateTask('notes', [newNote, ...(task.notes || [])])
		} catch (error) {
			showSnackbar('Error adding note', { variant: 'error' })
		}
	}

	const handleResolveFlaggedTask = async () => {
		if (!task) return
		setIsResolving(true)
		dispatch(
			projectActions.resolveFlaggedTask({
				taskId: task._id,
				projectId: task.project_id,
			}),
		)
		setIsResolving(false)
		goToTask()
	}

	return (
		<Box>
			{!!task?.isFlagged && (
				<Box flex={1} mb={1} display="flex" justifyContent="flex-end">
					<Button
						color="success"
						variant="outlined"
						onClick={handleResolveFlaggedTask}
						isLoading={isResolving}
					>
						Resolve issue
					</Button>
				</Box>
			)}
			<AddNote onAddNote={handleAddNote} />
			{!!notes.length && <AllNotes notes={notes} />}
		</Box>
	)
}
