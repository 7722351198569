import { ComponentType, useContext, useMemo } from 'react'
import { TableTypes } from '@cango-app/types'
import { TablesSdk } from '@cango-app/sdk'
import { Control, useWatch } from 'react-hook-form'

import { TableContext } from 'src/providers/table-provider'
import { Chip } from 'src/components'

import { allOperators } from './utils'
import { ConfigureCalculationForm } from './types'

export const Slice: ComponentType<{
	sliceIndex: number
	onClick?: () => void
	onDelete: () => void
	control: Control<ConfigureCalculationForm>
	rowId?: string
}> = ({ sliceIndex, onClick, control, onDelete, rowId }) => {
	const slice = useWatch({ control, name: `calculation.${sliceIndex}` })
	const { mappedColumns, table, tableList, mappedRecords } = useContext(TableContext)

	const label = useMemo(() => {
		switch (slice.type) {
			case TableTypes.FormulaSliceType.FIELD:
				return mappedColumns.get(slice.value)?.name

			case TableTypes.FormulaSliceType.SINGLE_SELECT_OPTION: {
				const [columnId, optionId] = slice.value.split('::')
				const column = mappedColumns.get(columnId)
				if (!column) {
					return ''
				}
				const option = column.valueOptions.find(({ _id }) => _id === optionId)
				return `${column.name}:${option?.label}`
			}
			case TableTypes.FormulaSliceType.OPERATOR:
				return allOperators.find(({ _id }) => _id === slice.value)?.label
			case TableTypes.FormulaSliceType.NUMBER:
				return slice.value
			case TableTypes.FormulaSliceType.LOOKUP:
				return `Lookup from ${
					tableList.find(({ _id }) => _id === slice.lookup?.tableId)?.name ?? 'TBC'
				}`
			case TableTypes.FormulaSliceType.VLOOKUP: {
				if (!slice?.vlookup?.sourceCompareFieldId) {
					return ''
				}
				const sourceColumnId = slice.vlookup.sourceCompareFieldId.split('::')[0]
				const sourceColumn = mappedColumns.get(sourceColumnId)
				const isQuestionaire = sourceColumn?.type === TableTypes.FieldType.QUESTIONAIRE_REFERENCE
				return `Lookup: ${sourceColumn?.name ?? 'TBC'}${isQuestionaire ? ':Answer' : ''} --> ${tableList.find(({ _id }) => _id === slice.vlookup?.targetTableId)?.name ?? 'TBC'}`
			}
			case TableTypes.FormulaSliceType.REFERENCE_FIELD: {
				if (!slice.reference_column) {
					return ''
				}
				const mainColumn = mappedColumns.get(slice.reference_column)
				if (!mainColumn) {
					return ''
				}

				if (slice.value === TableTypes.QUESTIONAIRE_REFERENCE_ANSWER_PLACEHOLDER) {
					return `${mainColumn.name}.Answer`
				}

				if (!table?.referenceColumnNames[slice.reference_column]) {
					return ''
				}

				const row = mappedRecords.get(rowId ?? '')
				if (slice.value.includes('option:') && row) {
					const optionId = slice.value.split(':')[1]
					const options = TablesSdk.getQuestionOptions({
						referenceColumns: table.referenceColumnNames ?? {},
						fieldId: slice.reference_column,
						row,
					})
					return `${mainColumn.name}.Answer:${options.find(({ _id }) => _id === optionId)?.label}`
				}

				const referencedColumn = table?.referenceColumnNames[slice.reference_column].find(
					(_refCol) => _refCol._id === slice.value,
				)?.label
				return `${mainColumn.name}.${referencedColumn}`
			}
			default:
				return ''
		}
	}, [slice])

	return (
		<Chip
			key={sliceIndex}
			sx={{ mr: 1 }}
			label={label}
			onClick={onClick}
			onDelete={onDelete}
			variant="filled"
			color="primary"
		/>
	)
}
