import React, { ComponentType, useContext, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TableTypes } from '@cango-app/types'

import { Box } from '../box'
import { TableContext } from '../../providers/table-provider'
import { TableFilters } from '../table-filters'

export const ColumnsAndFilters: ComponentType<{ viewId: string | undefined }> = ({ viewId }) => {
	const { table } = useContext(TableContext)
	const { control } = useFormContext<TableTypes.TableView>()

	const selectedView = useMemo(() => {
		if (!table || !viewId) {
			return
		}

		return table.views.find((view) => view._id === viewId)
	}, [viewId, table?.views])

	if (viewId && !selectedView) {
		return null
	}

	return (
		<Box>
			<Box height={50} width={175}>
				<Controller
					control={control}
					name="filters"
					render={({ field: { onChange } }) => {
						return (
							<TableFilters
								initialState={{
									filter: {
										filterModel: selectedView?.filters,
									},
								}}
								onChange={onChange}
							/>
						)
					}}
				/>
			</Box>
		</Box>
	)
}
