import { TableTypes } from '@cango-app/types'

import { convertRowValueToArray } from '../utils'
import { Question } from '../../../components/question-flow/types'

export const convertRowValueToQuestionaireOption = (rowValue: any) => {
	const defaultValue: {
		answerType: TableTypes.AnswerType
		options: TableTypes.ListOption[]
	} = {
		answerType: TableTypes.AnswerType.SingleSelect,
		options: [],
	}
	if (!rowValue) {
		return defaultValue
	}

	if (!rowValue['answerType'] || !rowValue['options']) {
		return defaultValue
	}

	return {
		answerType: rowValue['answerType'],
		options: convertRowValueToArray(rowValue['options']),
	}
}

/**
 * Sorts nodes dynamically based on an array of column IDs.
 * @param nodes - Array of nodes to sort.
 * @param sortBy - Array of column IDs to sort by, in hierarchical order.
 * @returns A sorted array of nodes.
 */
export function sortNodesByFields(nodes: Question[], sortBy: string[]): Question[] {
	return [...nodes].sort((a, b) => {
		for (const field of sortBy) {
			const aValue = a.hierarchy[field] || '' // Handle missing values
			const bValue = b.hierarchy[field] || ''
			if (aValue < bValue) return -1
			if (aValue > bValue) return 1
		}
		return 0 // Equal for all fields in sortBy
	})
}

export function sortNodesWithDescendants(nodes: Question[], sortBy: string[]): Question[] {
	function attachDescendants(sortedList: Question[], allNodes: Question[]): Question[] {
		const result: Question[] = []

		for (const node of sortedList) {
			result.push(node) // Add the current node
			const descendants = node.descendants
				.map((descendant) => allNodes.find((n) => n._id === descendant._id))
				.filter(Boolean) as Question[] // Filter valid descendants

			if (descendants.length > 0) {
				const sortedDescendants = attachDescendants(
					sortNodesByFields(descendants, sortBy),
					allNodes,
				)
				result.push(...sortedDescendants) // Append descendants after the parent
			}
		}

		return result
	}

	const sortedNodes = sortNodesByFields(nodes, sortBy)
	return attachDescendants(sortedNodes, nodes)
}
