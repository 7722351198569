import { ComponentType } from 'react'

import { Text } from '../text'

import { Question } from './types'

type Props = {
	activeQuestion: Question
}

export const QuestionTitle: ComponentType<Props> = ({ activeQuestion }) => {
	return (
		<>
			<Text variant="h4" textAlign="center">
				{activeQuestion.question}
			</Text>
			{activeQuestion.info.map((info) => (
				<Text key={info} variant="overline">
					{info}
				</Text>
			))}
		</>
	)
}
