import { ComponentType, useContext, useState } from 'react'
import { TableTypes } from '@cango-app/types'

import { Box } from '../box'
import { Button } from '../button' // Delete after completing this task
import { TableContext } from '../../providers/table-provider'

import { QuestionStepper } from './question-stepper'
import { QuestionCard } from './question-card'
import { QuestionTitle } from './question-title'
import { QuestionEffect } from './question-effect'
import { AnswerManager } from './answers-manager'
import { Question } from './types'

type QuestionFlowContainer = {
	questions: Question[]
	onAnswerClick: (answerId: TableTypes.QuestionaireResponseItem[]) => void
	activeQuestion: Question | undefined
	activeQuestionIndex: number
	goBack: () => void
	goForward: () => void
	onSave: () => void
}

export const QuestionFlowContainer: ComponentType<QuestionFlowContainer> = ({
	questions,
	onAnswerClick,
	activeQuestion,
	goBack,
	goForward,
	activeQuestionIndex,
	onSave,
}) => {
	const { isUpdatingTable } = useContext(TableContext)
	const [isGoingForward, setIsGoingForward] = useState(true)

	const handleAnswerClick = (answer: TableTypes.QuestionaireResponseItem[]) => {
		if (!activeQuestion) {
			return
		}
		setIsGoingForward(true)
		onAnswerClick(answer)
	}

	return (
		<Box>
			<Box flex={1} padding={4} display="flex" flexDirection="column" alignItems="center">
				{!!activeQuestion && (
					<QuestionEffect activeQuestionId={activeQuestion?._id} isGoingForward={isGoingForward}>
						<QuestionCard>
							<QuestionTitle activeQuestion={activeQuestion} />
							<AnswerManager
								answerOptions={activeQuestion.options}
								onAnswerClick={handleAnswerClick}
								answer={activeQuestion.answer}
								answerType={activeQuestion.type}
							/>
						</QuestionCard>
					</QuestionEffect>
				)}
				<QuestionStepper
					activeQuestionIndex={activeQuestionIndex}
					handleBefore={goBack}
					handleNext={goForward}
					handleIsGoingForward={(goingForward: boolean) => setIsGoingForward(goingForward)}
					numberOfSteps={questions.filter(({ skip }) => !skip).length}
				/>
			</Box>
			<Box width="100%">
				<Button onClick={onSave} isLoading={isUpdatingTable}>
					Save answers
				</Button>
			</Box>
		</Box>
	)
}
