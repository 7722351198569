import React, { ComponentType } from 'react'
import MuiModal, { ModalProps as MuiModalProps } from '@mui/material/Modal'
import { SxProps } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'

import { useIsMobile } from 'src/hooks/useMobile'

import { CloseIcon } from '../assets/icons'

import { Box } from './box'

const style: SxProps = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxWidth: '100vw',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
	borderRadius: '14px',
	maxHeight: '90vh',
	overflow: 'scroll',
}

type ModalProps = Omit<MuiModalProps, 'onClose'> & {
	containerStyle?: SxProps
	withCloseButton?: boolean
	onClose?: (event?: any, reason?: 'backdropClick' | 'escapeKeyDown') => void
}

export const Modal: ComponentType<ModalProps> = ({
	children,
	containerStyle,
	withCloseButton,
	onClose,
	...props
}) => {
	const isMobile = useIsMobile()

	if (isMobile) {
		return (
			<SwipeableDrawer
				anchor="bottom"
				open={props.open}
				onClose={() => {
					window.scrollTo(0, 0)
					!!onClose && onClose()
				}}
				onOpen={() => {
					// required callback
				}}
			>
				<Box sx={{ overflowY: 'auto', ...containerStyle }}>{children}</Box>
			</SwipeableDrawer>
		)
	}

	return (
		<MuiModal {...props} onClose={onClose}>
			<Box position="relative" sx={{ ...style, ...containerStyle }}>
				{!!withCloseButton && !!onClose && (
					<IconButton
						sx={{ position: 'absolute', right: '16px', top: '16px' }}
						onClick={(event) => onClose(event, 'escapeKeyDown')}
					>
						<CloseIcon />
					</IconButton>
				)}
				{children}
			</Box>
		</MuiModal>
	)
}
