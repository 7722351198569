import { ComponentType, useEffect, useState, MouseEvent } from 'react'
import prettyBytes from 'pretty-bytes'
import { FilesSdk } from '@cango-app/sdk'
import dayjs from 'dayjs'
import { PulseLoader } from 'react-spinners'
import { Stack } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { useSelector } from 'react-redux'

import { colors } from 'src/theme/colors'

import { Box, IBoxProps } from '../box'
import { Text } from '../text'
import { CloseIcon, EditPenIcon, TrashIcon } from '../../assets/icons'
import { IconButton } from '../icon-button'
import { TextField } from '../text-field'
import { selectors as authSelectors } from '../../store/modules/auth'
import { showSnackbar } from '../../helpers/snackbarManager'

type AttachedFileComponentProps = {
	file?: FilesSdk.File
	isLoading?: boolean
	onClick?: () => void
	containerProps?: IBoxProps
	isUploadingAsync: boolean
	onUpdateFile?: (file: FilesSdk.File) => void
	onRemoveFile?: () => void
}

export const AttachedFileComponent: ComponentType<AttachedFileComponentProps> = ({
	file,
	isLoading,
	onClick,
	containerProps = {},
	isUploadingAsync,
	onUpdateFile,
	onRemoveFile,
}) => {
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [thumbnailLoaded, setThumbnailLoaded] = useState(false)
	const [isRenaming, setIsRenaming] = useState(false)
	const [fileName, setFileName] = useState('')
	const [isUpdatingFileName, setIsUpdatingFileName] = useState(false)
	const isReady = !isLoading && file
	const { sx: containerSx, ...restContainerProps } = containerProps
	const containerStyles = {
		...containerSx,
		cursor: isReady ? 'pointer' : undefined,
		'&:hover': isReady ? { bgcolor: colors.neutral['10'] } : undefined,
	}

	const handleEditClick = (e: MouseEvent) => {
		e.stopPropagation()
		if (!file) {
			return
		}
		setFileName(file.name)
		setIsRenaming(true)
	}

	const handleRemoveFile = (e: MouseEvent) => {
		e.stopPropagation()
		if (!onRemoveFile) {
			return
		}
		onRemoveFile()
	}

	const handleEditNameSave = async () => {
		if (!file || !onUpdateFile) {
			return
		}
		const newName = fileName.trim()
		try {
			setIsUpdatingFileName(true)
			const newFileResponse = await FilesSdk.update(
				import.meta.env.VITE_API as string,
				authHeaders,
				file.id,
				{ name: newName },
			)
			onUpdateFile(newFileResponse)
			setIsRenaming(false)
		} catch (error) {
			showSnackbar('Error updating file name', { variant: 'error' })
		} finally {
			setIsUpdatingFileName(false)
		}
	}

	useEffect(() => {
		if (file?.thumbnailLink) {
			const img = new Image()
			img.onload = () => setThumbnailLoaded(true)
			img.onerror = () => setThumbnailLoaded(false)
			img.src = file.thumbnailLink
		}
	}, [file?.thumbnailLink])

	if (isRenaming && file) {
		return (
			<Stack my={1} direction="row" flex={1} alignItems="center" minWidth={200}>
				<TextField
					fullWidth
					containerProps={{ flex: 1 }}
					value={fileName}
					onChange={(e) => setFileName(e.target.value)}
				/>
				<IconButton
					onClick={() => {
						setIsRenaming(false)
						setFileName(file.name)
					}}
				>
					<CloseIcon stroke={colors.error.main} />
				</IconButton>
				<IconButton onClick={handleEditNameSave} isLoading={isUpdatingFileName}>
					<CheckIcon />
				</IconButton>
			</Stack>
		)
	}

	return (
		<Box
			border={1}
			borderColor={colors.neutral['40']}
			display="flex"
			borderRadius="8px"
			minHeight="75px"
			overflow="hidden"
			{...restContainerProps}
			sx={containerStyles}
			onClick={isReady ? onClick : undefined}
		>
			{!isReady ? (
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					minWidth="170px"
					px={2}
				>
					<PulseLoader size={8} />
					{
						<Text fontSize={12}>
							{!isUploadingAsync ? 'Fetching file from Google drive' : 'File uploading...'}
						</Text>
					}
				</Box>
			) : (
				<>
					{thumbnailLoaded && !!file.thumbnailLink ? (
						<Box mr={2}>
							<img
								src={file.thumbnailLink}
								alt={file.name}
								width={100}
								onError={() => setThumbnailLoaded(false)}
							/>
						</Box>
					) : (
						<Box
							width={75}
							display="flex"
							alignItems="center"
							justifyContent="center"
							bgcolor={colors.neutral['10']}
						>
							<img
								src={file.iconLink.replace('16', '128')}
								alt={file.name}
								width="26px"
								height="26px"
							/>
						</Box>
					)}

					<Box px={2} display="flex" flexDirection="column" justifyContent="center">
						<Text color={colors.info.main}>{file.name}</Text>
						{!!file.size && <Text fontSize={12}>{prettyBytes(parseInt(file.size))}</Text>}
						<Text fontSize={12}>
							Last updated: {dayjs(file.modifiedTime).format('MMM DD, YYYY HH:mm')}
						</Text>
					</Box>
					<Stack px={1} display="flex" justifyContent="center" alignItems="center">
						{!!onUpdateFile && (
							<IconButton sx={{ mb: 0.2 }} onClick={handleEditClick}>
								<EditPenIcon stroke={colors.feldgrau['60']} width={20} />
							</IconButton>
						)}
						{!!onRemoveFile && (
							<IconButton sx={{ mt: 0.2 }} onClick={handleRemoveFile}>
								<TrashIcon stroke={colors.error.light['50']} width={16} />
							</IconButton>
						)}
					</Stack>
				</>
			)}
		</Box>
	)
}
