import { ComponentType, useCallback, useContext, useEffect, useState } from 'react'
import { TablesSdk } from '@cango-app/sdk'
import { TableTypes } from '@cango-app/types'
import { useSelector } from 'react-redux'
import PulseLoader from 'react-spinners/PulseLoader'
import { v4 } from 'uuid'
import { GridFilterModel } from '@mui/x-data-grid-premium'

import { selectors as authSelectors } from 'src/store/modules/auth'
import { TableContext } from 'src/providers/table-provider'
import { getLayout } from 'src/modules/tables/questionaire-logic/get-layout'
import { applyFilterItem, applyFilterModelToRow, columnValueGetter } from 'src/modules/tables/utils'
import { getFieldType } from 'src/modules/tables/mui-formatter'
import { sortNodesWithDescendants } from 'src/modules/tables/questionaire-logic/utils'
import { showSnackbar } from 'src/helpers/snackbarManager'
import {
	BasicQuestionaireData,
	CangoQuestionaireNode,
} from 'src/modules/tables/questionaire-logic/types'

import { Box } from '../box'

import { QuestionFlowContainer } from './question-flow-container'
import { Question } from './types'

type QuestionFlowLogicContainerProps = {
	filters: GridFilterModel
	acceptedQuestionColumns: string[]
	questionScope: string[]
}

const getQuestionParents = ({
	questionId,
	visitedNodes,
	questions,
}: {
	questionId: string
	visitedNodes?: Set<string>
	questions: TableTypes.Record[]
}): string[] => {
	if (!visitedNodes) {
		visitedNodes = new Set<string>()
	}
	if (visitedNodes.has(questionId)) {
		return []
	}
	visitedNodes.add(questionId)
	const parents = questions.filter((_ques) =>
		_ques.descendants?.some((_desc) => _desc.row === questionId),
	)
	let parentIds = parents.map((_parent) => _parent._id)
	if (parents.length) {
		parentIds = [
			...parents
				.map((_parent) =>
					getQuestionParents({
						questionId: _parent._id,
						visitedNodes,
						questions,
					}),
				)
				.flat(),
			...parentIds,
		]
	}

	return parentIds
}

const convertQuestionTableIntoQuestions = ({
	questionTable,
	rowsAfterFilter,
	mappedColumns,
	questionScope,
	questionaireAnswers,
	acceptedQuestionColumns,
}: {
	questionTable: TableTypes.CangoTable
	rowsAfterFilter: TableTypes.Record[]
	mappedColumns: Map<string, TableTypes.Field>
	questionScope: string[]
	questionaireAnswers: TableTypes.QuestionaireAnswer[]
	acceptedQuestionColumns: string[]
}): Question[] => {
	const questionTableQuestionId = questionTable.principal_field ?? ''
	const questionTableOptionsId =
		questionTable.fields.find((_field) => _field.type === TableTypes.FieldType.OPTIONS)?._id ?? ''

	const topsortedQuestionNodes = getLayout({
		rows: questionTable.records,
		questionColumnId: questionTableQuestionId,
		optionsColumnId: questionTableOptionsId,
	})

	const questionColumns = [...mappedColumns.values()].filter(
		(_col) =>
			_col.type === TableTypes.FieldType.QUESTIONAIRE_REFERENCE &&
			(!acceptedQuestionColumns.length || acceptedQuestionColumns.includes(_col._id)),
	)
	const questionColumnIds = questionColumns.map((_col) => _col._id)

	const scopeAskedQuestions = new Map<string, Set<string>>()

	const createScopeKey = (row: TableTypes.Record): string => {
		return questionScope.map((_colId) => row.data[_colId] || '').join('>')
	}

	return rowsAfterFilter.reduce((_questions: Question[], _row) => {
		const _rowQuestions = questionColumnIds.reduce(
			(
				_acc: { colId: string; question: CangoQuestionaireNode<BasicQuestionaireData> }[],
				_colId,
			) => {
				const questionId = _row.data[_colId] as string | undefined
				if (!questionId) {
					return _acc
				}

				const getQuestion = (question_id: string) => {
					return topsortedQuestionNodes.nodes.find((_node) => _node.id === question_id)
				}

				const question = getQuestion(questionId)

				if (!question) {
					return _acc
				}

				const questionParents = getQuestionParents({
					questionId,
					questions: questionTable.records,
				})

				questionParents.forEach((_parent) => {
					const parentQuestion = getQuestion(_parent)
					if (parentQuestion) {
						_acc.push({
							colId: _colId,
							question: parentQuestion,
						})
					}
				})

				_acc.push({
					colId: _colId,
					question: question,
				})
				return _acc
			},
			[],
		)

		const constructedQuestions = _rowQuestions.reduce((_acc: Question[], _rowQuestion) => {
			const question = _rowQuestion.question
			if (questionScope.length === 0 && _acc.some((_q) => _q._id === question.id)) {
				return _acc
			}

			const questionAnsweredInHigherScope = questionaireAnswers.some((_answer) => {
				return (
					_answer.question_id === question.id &&
					_answer.scope.length < questionScope.length &&
					_answer.scope.every((_scope) => {
						return questionScope.some((_colId) => _row.data[_colId] === _scope.value)
					})
				)
			})

			if (questionAnsweredInHigherScope) {
				return _acc
			}

			const questionRow = questionTable.records.find((_record) => _record._id === question.id)
			const scopeKey = createScopeKey(_row)

			if (!scopeAskedQuestions.has(scopeKey)) {
				scopeAskedQuestions.set(scopeKey, new Set<string>())
			}

			const askedInScope = scopeAskedQuestions.get(scopeKey)
			if (!askedInScope || askedInScope.has(question.id)) {
				return _acc
			}

			askedInScope.add(question.id)

			const answer = questionaireAnswers.reduce(
				(_answer: TableTypes.QuestionaireResponseItem[] | undefined, _response) => {
					if (_answer) {
						return _answer
					}

					if (_response.question_id !== question.id) {
						return undefined
					}

					if (!_response.scope.length) {
						return _response.answer
					}

					if (_response.scope.every(({ column_id, value }) => _row.data[column_id] === value)) {
						return _response.answer
					}
				},
				undefined,
			)

			const questionId = `${_row._id}-${_rowQuestion.colId}-${questionScope.join('-')}`

			_acc.push({
				_id: questionId,
				info: questionScope.map((_colId) => {
					const mainTableColumn = mappedColumns.get(_colId)
					if (!mainTableColumn) return ''
					return `${mainTableColumn.name}: ${columnValueGetter(_row?.data[_colId], mainTableColumn, new Map(mainTableColumn.valueOptions?.map((_opt) => [_opt._id, _opt]) ?? []))}`
				}),
				question: question.data.label,
				type: question.data.answerConfig.answerType,
				options: question.data.answerConfig.options,
				descendants: questionRow?.descendants ?? [],
				rowId: _row._id,
				columnId: _rowQuestion.colId,
				questionRowId: question.id,
				answer: answer ?? [],
				hierarchy: questionScope.reduce((_acc: Record<string, any>, _colId) => {
					return {
						..._acc,
						[_colId]: _row.data[_colId],
					}
				}, {}),
				skip: false,
			})
			return _acc
		}, [])

		return [..._questions, ...constructedQuestions]
	}, [])
}

const getQuestionaireQuestions = ({
	mainTable,
	questionTable,
	filters,
	mappedColumns,
	questionScope,
	acceptedQuestionColumns,
}: {
	mainTable: TableTypes.CangoTable
	questionTable: TableTypes.CangoTable
	filters: GridFilterModel
	mappedColumns: Map<string, TableTypes.Field>
	questionScope: string[]
	acceptedQuestionColumns: string[]
}): Question[] => {
	const rowsAfterFilter = [...(mainTable?.records ?? [])].filter((_row) => {
		if (!filters.items.length) return true
		return applyFilterModelToRow({
			columns:
				mainTable?.fields.map((_field) => ({
					_id: _field._id,
					type: getFieldType(_field.type, _field.returnType),
				})) ?? [],
			row: _row.data,
			filterModel: filters,
		})
	})

	return convertQuestionTableIntoQuestions({
		questionTable: questionTable,
		rowsAfterFilter,
		mappedColumns,
		questionScope,
		questionaireAnswers: mainTable.questionaire_answers ?? [],
		acceptedQuestionColumns,
	})
}

const getUpdatedQuestionaireFromAnswer = ({
	questionaire,
	question,
	answer,
}: {
	questionaire: Question[]
	question: Question
	answer: TableTypes.QuestionaireResponseItem[]
}): Question[] => {
	const childrenRowIds = (questionId: string, visitedQuestions = new Set<string>()): string[] => {
		if (visitedQuestions.has(questionId)) {
			return []
		}
		visitedQuestions.add(questionId)
		const parentQuestion = questionaire.find((_ques) => questionId === _ques.questionRowId)
		if (!parentQuestion) {
			return []
		}
		return parentQuestion.descendants.reduce((_childIds: string[], _desc) => {
			if (!_desc.row) {
				return _childIds
			}
			return [..._childIds, _desc.row, ...childrenRowIds(_desc.row, visitedQuestions)]
		}, [])
	}

	const childrenWithConditions = question.descendants.filter(
		(_descendant) => _descendant.option_condition?.values.length,
	)

	let successfulConditions = childrenWithConditions.reduce((_acc: string[], _desc) => {
		const optionCondition = _desc.option_condition
		if (!optionCondition) {
			return _acc
		}

		const matchesOptionCondition = optionCondition.values.some((_val) => {
			return answer.some((_ans) =>
				applyFilterItem('string', optionCondition.operator, _ans.option_id ?? _ans.text, _val),
			)
		})

		if (matchesOptionCondition) {
			return [..._acc, _desc._id]
		}

		return _acc
	}, [])

	if (!successfulConditions.length) {
		successfulConditions = question.descendants
			.filter((_descendant) => !_descendant.option_condition?.values.length)
			.map((_desc) => _desc._id)
	}

	const questionsToSkip = question.descendants.reduce((_acc: string[], _desc) => {
		if (!_desc.row) {
			return _acc
		}
		if (!successfulConditions.includes(_desc._id)) {
			return [..._acc, _desc.row, ...childrenRowIds(_desc.row)]
		}
		return _acc
	}, [])

	return [...questionaire].map((_question) => {
		const shouldSkip = questionsToSkip.includes(_question.questionRowId)
		return {
			..._question,
			skip: shouldSkip,
			answer: shouldSkip ? [] : _question.answer,
		}
	})
}

export const QuestionFlowLogicContainer: ComponentType<QuestionFlowLogicContainerProps> = ({
	filters,
	acceptedQuestionColumns,
	questionScope,
}) => {
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const { associatedQuestionaireId, table, mappedColumns, updateTableConfig } =
		useContext(TableContext)
	const [isLoadingQuestionaire, setIsLoadingQuestionaire] = useState(false)
	const [questionaireTable, setQuestionaireTable] = useState<TableTypes.CangoTable>()
	const [questionaire, setQuestionaire] = useState<Question[]>([])
	const [activeQuestionIndex, setActiveQuestionIndex] = useState(0)
	const [stepHistory, setStepHistory] = useState<number[]>([])

	const findNextIndex = (distance = 1) => {
		const nextQuestion = questionaire[activeQuestionIndex + distance]
		if (!nextQuestion) return activeQuestionIndex
		if (nextQuestion.skip) {
			return findNextIndex(distance + 1)
		}
		return activeQuestionIndex + distance
	}

	const goToNextStep = () => {
		const nextIndex = findNextIndex()
		setStepHistory((prev) => [...prev, activeQuestionIndex])
		setActiveQuestionIndex(nextIndex)
	}

	const fetchTable = useCallback(async () => {
		setIsLoadingQuestionaire(true)
		try {
			if (!table) {
				throw new Error('Table not found')
			}
			const { table: questionTable } = await TablesSdk.getTable(
				import.meta.env.VITE_API as string,
				authHeaders,
				associatedQuestionaireId,
			)

			setQuestionaireTable(questionTable)

			const questions = getQuestionaireQuestions({
				mainTable: table,
				questionTable,
				filters,
				mappedColumns,
				questionScope,
				acceptedQuestionColumns,
			})

			const sortedQuestions = sortNodesWithDescendants(questions, questionScope)

			setQuestionaire(sortedQuestions)
		} catch (error) {
			showSnackbar('Failed to generate questionaire', { variant: 'error' })
		} finally {
			setIsLoadingQuestionaire(false)
		}
	}, [filters, table])

	const handleAnswerClick = (answer: TableTypes.QuestionaireResponseItem[]) => {
		if (questionaire.length === 0 || !questionaireTable || !table) return
		const question = questionaire[activeQuestionIndex]

		const newQuestionaire = getUpdatedQuestionaireFromAnswer({ questionaire, question, answer })
		const newOrderedQuestionaire = sortNodesWithDescendants(newQuestionaire, questionScope)
		let questionType: TableTypes.AnswerType = TableTypes.AnswerType.SingleSelect
		const newQuestions = newOrderedQuestionaire.map((_question) => {
			if (_question.questionRowId === question.questionRowId) {
				_question.answer = answer
				questionType = _question.type
			}
			return _question
		})

		setQuestionaire([...newQuestions])
		if (questionType === TableTypes.AnswerType.SingleSelect) {
			goToNextStep()
		}
	}

	const handleSaveAnswers = async () => {
		const newAnswers = questionaire.reduce((acc: TableTypes.QuestionaireAnswer[], _question) => {
			acc.push({
				_id: v4(),
				question_id: _question.questionRowId,
				answer: _question.answer,
				scope: Object.entries(_question.hierarchy).map(([column_id, value]) => ({
					column_id,
					value,
				})),
			})
			return acc
		}, [])

		const oldAnswers =
			table?.questionaire_answers?.reduce(
				(_updatedAnswers: TableTypes.QuestionaireAnswer[], _oldAnswer) => {
					const isUpdated = newAnswers.some((_newAnswer) => {
						if (_newAnswer.question_id === _oldAnswer.question_id) {
							return false
						}
						if (_newAnswer.scope.length !== _oldAnswer.scope.length) {
							return false
						}
						return _newAnswer.scope.every((_newScopeItem) => {
							return _oldAnswer.scope.some(
								(_oldScopeItem) =>
									_newScopeItem.column_id === _oldScopeItem.column_id &&
									_newScopeItem.value === _oldScopeItem.value,
							)
						})
					})
					if (isUpdated) {
						return _updatedAnswers
					}
					return [..._updatedAnswers, _oldAnswer]
				},
				[],
			) ?? []

		await updateTableConfig({ questionaire_answers: [...oldAnswers, ...newAnswers] })
	}

	useEffect(() => {
		fetchTable()
	}, [])

	if (isLoadingQuestionaire) {
		return (
			<Box display="flex" flexDirection="row" alignItems="center">
				<PulseLoader size={6} />
			</Box>
		)
	}

	if (!questionaire.length) {
		return (
			<Box display="flex" flexDirection="row" alignItems="center">
				No questions to display
			</Box>
		)
	}

	return (
		<QuestionFlowContainer
			questions={questionaire}
			onAnswerClick={handleAnswerClick}
			activeQuestion={questionaire[activeQuestionIndex]}
			activeQuestionIndex={activeQuestionIndex}
			goBack={() => {
				const lastStep = stepHistory.pop()
				if (lastStep !== undefined) {
					setActiveQuestionIndex(lastStep)
				}
			}}
			goForward={goToNextStep}
			onSave={handleSaveAnswers}
		/>
	)
}
