import { Utils, ClientTypes, V3BlueprintTypes } from '@cango-app/types'
import capitalize from 'lodash/capitalize'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)
dayjs.extend(duration)

const cangoDateFormat = "ddd D MMM 'YY"

export const roleLabel = (roles: ClientTypes.Role[], roleID: string): string | undefined => {
	try {
		return [...roles, { label: '< Anyone >', _id: 'anyone' }].find(
			(role: any) => role._id === roleID,
		)?.label
	} catch (error) {
		return 'unassigned'
	}
}

export const readableList = (arr: string[] | string, operator = '&'): string => {
	if (!arr.length) return ''
	if (typeof arr === 'string') return arr
	return arr.length > 1
		? arr.slice(0, -1).join(', ') + ' ' + operator + ' ' + arr.slice(-1)
		: arr[0]
}

export const dateLabel = (timestamp: number): string => {
	return timestamp ? String(dayjs.unix(timestamp).format(cangoDateFormat)) : 'Unknown'
}

export const relativeTimeLabel = (timestamp: number): string => {
	return timestamp ? String(dayjs.unix(timestamp).fromNow()) : 'Just now'
}

export const relativeDaysLabel = (days: number | undefined): string => {
	if (typeof days === 'undefined' || +days === 0) return 'Day of'
	return `${Math.abs(+days)} day${Math.abs(+days) > 1 ? 's' : ''} ${+days < 0 ? 'before' : 'after'}`
}

export const getActionLabel = (action: V3BlueprintTypes.ActionEnum | string): string => {
	switch (action) {
		case V3BlueprintTypes.ActionEnum.FileTemplate: {
			return 'Templated file'
		}
		case V3BlueprintTypes.ActionEnum.FileUpload: {
			return 'Upload file'
		}
		case V3BlueprintTypes.ActionEnum.Contact: {
			return 'Add a contact'
		}
		case V3BlueprintTypes.ActionEnum.SetResources: {
			return 'Set resources'
		}
		case V3BlueprintTypes.ActionEnum.TaskReference: {
			return 'Task reference(s)'
		}
		default: {
			return capitalize(action)
		}
	}
}

export const getTaskPastProgressive = (action?: V3BlueprintTypes.ActionEnum): string => {
	switch (action) {
		case V3BlueprintTypes.ActionEnum.Call: {
			return 'called'
		}
		case V3BlueprintTypes.ActionEnum.Email: {
			return 'emailed'
		}
		case V3BlueprintTypes.ActionEnum.Invite: {
			return 'invited'
		}
		case V3BlueprintTypes.ActionEnum.Meeting: {
			return 'met'
		}
		default: {
			return 'Unknown'
		}
	}
}

export const getBlueprintWhenTypeLabel = (whenType: V3BlueprintTypes.WhenEnum): string => {
	switch (whenType) {
		case V3BlueprintTypes.WhenEnum.Milestone: {
			return capitalize(V3BlueprintTypes.WhenEnum.Milestone)
		}
		case V3BlueprintTypes.WhenEnum.Date: {
			return capitalize(V3BlueprintTypes.WhenEnum.Date)
		}
		case V3BlueprintTypes.WhenEnum.Recurring: {
			return capitalize(V3BlueprintTypes.WhenEnum.Recurring)
		}
		case V3BlueprintTypes.WhenEnum.ASAP: {
			return V3BlueprintTypes.WhenEnum.ASAP.toUpperCase()
		}
		default: {
			return capitalize(whenType)
		}
	}
}

export const getTrainingTypeLabel = (trainingType: V3BlueprintTypes.TrainingEnum): string => {
	switch (trainingType) {
		case V3BlueprintTypes.TrainingEnum.InPerson:
			return 'In person training'
		case V3BlueprintTypes.TrainingEnum.Online:
			return 'Online training'
		default:
			return 'No training required'
	}
}

export const getWeekdayLabel = (weekday: Utils.Weekdays): string => {
	switch (weekday) {
		case Utils.Weekdays.sunday:
			return 'Sunday'
		case Utils.Weekdays.monday:
			return 'Monday'
		case Utils.Weekdays.tuesday:
			return 'Tuesday'
		case Utils.Weekdays.wednesday:
			return 'Wednesday'
		case Utils.Weekdays.thursday:
			return 'Thursday'
		case Utils.Weekdays.friday:
			return 'Friday'
		case Utils.Weekdays.saturday:
			return 'Saturday'
		default:
			return 'Unknown day'
	}
}

export const taskActionLabel = (actionType: V3BlueprintTypes.ActionEnum): string => {
	switch (actionType) {
		case V3BlueprintTypes.ActionEnum.Video:
		case V3BlueprintTypes.ActionEnum.Software:
		case V3BlueprintTypes.ActionEnum.Invite:
		case V3BlueprintTypes.ActionEnum.Call:
		case V3BlueprintTypes.ActionEnum.Meeting:
		case V3BlueprintTypes.ActionEnum.Email:
			return actionType
		case V3BlueprintTypes.ActionEnum.Contact:
			return 'Create contact'
		case V3BlueprintTypes.ActionEnum.FileUpload:
			return `Upload file(s)`
		case V3BlueprintTypes.ActionEnum.FileTemplate:
			return 'File template'
		case V3BlueprintTypes.ActionEnum.Note:
			return actionType
		default:
			return actionType
	}
}
