import { List, ListItemButton, ListItemIcon, Checkbox, ListItemText } from '@mui/material'
import { ComponentType } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { TableTypes } from '@cango-app/types'
import { v4 } from 'uuid'

import { colors } from 'src/theme/colors'

import { Box } from '../box'
import { TextField } from '../text-field'

type Props = {
	answerType: TableTypes.AnswerType
	answerOptions: TableTypes.ListOption[]
	onAnswerClick: (answer: TableTypes.QuestionaireResponseItem[]) => void
	answer?: TableTypes.QuestionaireResponseItem[]
}

const OptionAnswerComponent: ComponentType<Props> = ({
	answerType,
	answerOptions,
	onAnswerClick,
	answer,
}) => {
	const anyAnswerHasImage = answerOptions.find(
		({ image }) => image && !_isEmpty(image) && image.path,
	)

	const handleAnswerClick = (itemOptionIdClicked: string) => {
		if (answerType === TableTypes.AnswerType.SingleSelect) {
			onAnswerClick([{ _id: v4(), option_id: itemOptionIdClicked }])
		} else if (answerType === TableTypes.AnswerType.MultiSelect) {
			const newAnswer = answer?.some(({ option_id }) => option_id === itemOptionIdClicked)
				? answer?.filter(({ option_id }) => option_id !== itemOptionIdClicked)
				: [...(answer || []), { _id: v4(), option_id: itemOptionIdClicked }]
			onAnswerClick(newAnswer)
		}
	}

	return (
		<List
			dense
			component="div"
			role="list"
			sx={{
				paddingTop: 4,
			}}
		>
			{answerOptions.map(({ _id, label, image }) => {
				const labelledId = _id
				return (
					<ListItemButton
						key={labelledId}
						disableGutters
						onClick={() => handleAnswerClick(labelledId)}
					>
						{anyAnswerHasImage ? (
							<Box width={150} height={150}>
								{image ? (
									<img
										src={image.path}
										alt={label}
										width={150}
										height={150}
										style={{
											objectFit: 'contain',
										}}
									/>
								) : (
									<Box
										width={150}
										height={150}
										sx={{
											backgroundColor: colors.neutral['10'],
										}}
									/>
								)}
							</Box>
						) : null}
						<ListItemIcon>
							<Checkbox
								disableRipple
								inputProps={{
									'aria-labelledby': labelledId,
								}}
								checked={answer?.some(({ option_id }) => option_id === labelledId)}
							/>
						</ListItemIcon>
						<ListItemText id={labelledId} primary={label} />
					</ListItemButton>
				)
			})}
		</List>
	)
}

const TextAnswerComponent: ComponentType<Props> = ({ answerType, onAnswerClick, answer }) => {
	const answerItem = answer?.[0]
	const answerId = answerItem?._id
	const answerText = answerItem?.text || ''
	const handleTextChange = (newText: string) => {
		onAnswerClick([{ _id: answerId ?? v4(), text: newText }])
	}

	return (
		<Box pt={4}>
			<TextField
				label="Answer"
				sx={{
					width: 400,
				}}
				value={answerText}
				onChange={(e) => handleTextChange(e.target.value)}
				multiline={answerType === TableTypes.AnswerType.LongText}
			/>
		</Box>
	)
}

export const AnswerManager: ComponentType<Props> = (props) => {
	if (
		[TableTypes.AnswerType.SingleSelect, TableTypes.AnswerType.MultiSelect].includes(
			props.answerType,
		)
	) {
		return <OptionAnswerComponent {...props} />
	}

	if (
		[TableTypes.AnswerType.ShortText, TableTypes.AnswerType.LongText].includes(props.answerType)
	) {
		return <TextAnswerComponent {...props} />
	}

	return null
}
