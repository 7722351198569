import { ComponentType, useContext, useMemo } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { Stack } from '@mui/material'
import { TableTypes } from '@cango-app/types'
import { TablesSdk } from '@cango-app/sdk'
import { v4 } from 'uuid'

import { colors } from '../../../../theme/colors'
import { Box, Chip, Grid, Text } from '../../../../components'
import { TableContext } from '../../../../providers/table-provider'

type ReferenceColumnProps = {
	onFieldClick: (data: TableTypes.FormulaSlice) => void
	rowId?: string
}

const ColumnsInReferencedTable: ComponentType<
	ReferenceColumnProps & {
		referenceColumn: string
		_id: string
		label: string
	}
> = ({ referenceColumn, _id, label, onFieldClick }) => {
	return (
		<Box marginRight={1} mb={1}>
			<Chip
				color="primary"
				variant="outlined"
				label={label}
				onClick={() => {
					onFieldClick({
						id: v4(),
						type: TableTypes.FormulaSliceType.REFERENCE_FIELD,
						reference_column: referenceColumn,
						value: _id,
					})
				}}
			/>
		</Box>
	)
}

const ReferenceColumn: ComponentType<ReferenceColumnProps & { columnId: string }> = ({
	columnId,
	rowId,
	...rest
}) => {
	const { table, mappedColumns, mappedRecords } = useContext(TableContext)
	const column = mappedColumns.get(columnId)
	const row = mappedRecords.get(rowId ?? '')

	const referencedTableColumnNames = useMemo(() => {
		if (!table?.referenceColumnNames || _isEmpty(table.referenceColumnNames)) {
			return []
		}

		const columnNames = table.referenceColumnNames[columnId]

		if (column?.type === TableTypes.FieldType.QUESTIONAIRE_REFERENCE) {
			return [
				...columnNames,
				{ _id: TableTypes.QUESTIONAIRE_REFERENCE_ANSWER_PLACEHOLDER, label: 'Answer' },
			]
		}

		return columnNames
	}, [table?.referenceColumnNames])

	const listOptions = useMemo((): TableTypes.ListOption[] => {
		if (column?.type !== TableTypes.FieldType.QUESTIONAIRE_REFERENCE) {
			return []
		}

		const referenceColumns = table?.referenceColumnNames

		if (!row || !row.references?.[columnId] || !referenceColumns?.[columnId]) {
			return []
		}

		return TablesSdk.getQuestionOptions({
			referenceColumns: referenceColumns,
			fieldId: columnId,
			row,
		})
	}, [column])

	if (!column) {
		return null
	}

	return (
		<Grid item xs={12} mb={2}>
			<Text component="label" fontSize={14} color={colors.neutral['80']} fontWeight={500}>
				{column.name}
			</Text>
			<Stack
				direction="row"
				flexWrap="wrap"
				marginY={0.5}
				sx={{
					overflowX: 'auto',
				}}
			>
				{referencedTableColumnNames.map(({ _id, label }) => (
					<ColumnsInReferencedTable
						{...rest}
						key={_id}
						_id={_id}
						label={label}
						referenceColumn={columnId}
					/>
				))}
			</Stack>
			{!!listOptions.length && (
				<Box>
					<Text component="label" fontSize={14} color={colors.neutral['80']} fontWeight={500}>
						{column.name}: Options
					</Text>
					<Stack
						direction="row"
						flexWrap="wrap"
						marginY={0.5}
						sx={{
							overflowX: 'auto',
						}}
					>
						{listOptions.map(({ _id, label }) => {
							const id = `option:${_id}`
							return (
								<ColumnsInReferencedTable
									{...rest}
									key={id}
									_id={id}
									label={label}
									referenceColumn={columnId}
								/>
							)
						})}
					</Stack>
				</Box>
			)}
		</Grid>
	)
}

export const ReferenceColumnList: ComponentType<ReferenceColumnProps> = (props) => {
	const { table } = useContext(TableContext)

	const referenceColumnIds = useMemo(() => {
		if (!table?.referenceColumnNames || _isEmpty(table.referenceColumnNames)) {
			return []
		}
		return Object.keys(table.referenceColumnNames).filter(
			(_columnId) => table.referenceColumnNames[_columnId].length,
		)
	}, [table?.referenceColumnNames])

	if (!referenceColumnIds.length) {
		return null
	}

	return (
		<>
			{referenceColumnIds.map((_columnId) => (
				<ReferenceColumn {...props} key={_columnId} columnId={_columnId} />
			))}
		</>
	)
}
